export default {
  menus: {
    about_us: "ABOUT US",
    mission_methodology: "OUR MISSION & METHODOLOGY",
    our_program: "OUR PROGRAM",
    meet_our_team: "MEET OUR TEAM",
    frequently_asked_questions: "FREQUENTLY ASKED QUESTIONS",
    work_with_us: "WORK WITH US",
    campus: "CAMPUS",
    barcelona: "BARCELONA",
    madrid: "MADRID",
    courses: "COURSES",
    spanish_course: "SPANISH COURSE",
    english_course: "ENGLISH COURSE",
    placement_test: "PLACEMENT TEST",
    exams_certification: "EXAMS & CERTIFICATES",
    prices: "PRICES & CATALOGUES",
    student_life: "STUDENT LIFE",
    student_visa: "STUDENT VISA",
    accommodation: "ACCOMMODATION",
    medical_insurance: "MEDICAL INSURANCE",
    extra_activities: "EXTRA ACTIVITIES",
    contact_us: "CONTACT US",
    pathway: "PATHWAY",
    packages: "PACKAGES",
    blog: "BLOG",
  },
  footer: {
    follow_us: "Follow us on social Media",
    follow_us_subtitle: "We have fun over here!",
    keep_in_touch: "Let's keep in touch!",
    keep_in_touch_subtitle:
      "If you don't want to miss any of our news, exclusive offers, activities and special events, subscribe to our Newsletter!",
    subscribe: "SUBSCRIBE",
    subscribe_link: "http://eepurl.com/hSd_25",
    legal_disclaimer: "Legal Disclaimer",
  },
  contact_form: {
    title: "Invest in your studies at a quality institution",
    subtitle:
      "Contact us and start planning your education in Spain. Start being part of the #NLCollegeCommunity",
    full_name: "Full name",
    accredited: "Center accredited by",
    accredited2: "the Cervantes institute",
    email: "E-mail",
    phone: "Telephone number / Whatsapp",
    country: "Country",
    nationality: "Nationality",
    campus: "Preferred Campus",
    message: "Message",
    terms: `I have read and accept the 
      <a href='/files/Política de Privacidad - NL College.pdf' download target='_blank'>NL College Privacy Policy</a>, 
      <a href='/files/T&C NL COLLEGE.pdf' download target='_blank'>Terms & Conditions, Cancellation Policy</a>, and 
      <a href='/files/POLÍTICA DE COOKIES - NL College.pdf' download target='_blank'>Cookies Policy</a>.
    `,
    attach_cv: "Attach your CV",
    tooBigError: "File cannot be more than 2mb",
    button: "SEND",
    contact_form: "Please accept the terms and conditions",
    success_msg: "Thanks, your message has been sent!",
  },
  price_calculator: {
    title: "CALCULATE THE PRICE",
    lang: "Language",
    es: "Spanish",
    en: "English",
    courses: "Courses",
    acceleratedmorning: "Accelerated Morning",
    acceleratedafternoon: "Accelerated Afternoon",
    semi: "Semi",
    extensive: "Extensive",
    weeks: "Weeks",
    weeks_placeholder: "1 to 42",
    price: "Price",
  },
  home: {
    header: {
      title: 'Live, learn & <span class="text-secondary">Speak!</span>',
      subtitle:
        "Study at NL College and learn a new language while having an amazing experience in Barcelona and Madrid",
    },
    learning: {
      header: "Learning",
      subheader: "a new language is one thing,",
      paragraph1:
        "But doing it in a completely different, innovative way is a whole other experience.",
      paragraph2:
        "Our campuses are located in privileged locations, and their modern facilities and multicultural classrooms will not only enrich your learning journey but also transform it into a more fun and exciting adventure.",
    },
    accreditation: {
      title: "Accreditations",
      subtitle:
        "NL College Madrid and Barcelona have been accredited by Instituto Cervantes, which means it meets the conditions set by the Instituto Cervantes Accreditation System for Centres, the only international accreditation exclusively focused on the teaching of Spanish as a foreign language.",
      dele_siele:
        "NL College is now an official examinator center of DELE and SIELE.",
      cambridge:
        "NL College is an authorized Cambridge English preparation center.",
    },
    tabs: [
      {
        image: "home-tabs-1",
        title: "Courses",
        subtitle:
          'Find the course that best <span class="text-secondary">fits your needs</span>',
        links: [
          {
            title: "Spanish",
            to: { name: "our_courses", hash: "#spanish" },
          },
          {
            title: "English",
            to: { name: "our_courses", hash: "#english" },
          },
          {
            title: "Exam preparation courses",
            to: { name: "our_courses", hash: "#exam_preparation" },
          },
        ],
      },
      {
        image: "home-tabs-2",
        title: "Campuses",
        subtitle:
          'Discover the best <span class="text-secondary">campuses in town</span>',
        links: [
          {
            title: "Barcelona",
            to: { name: "our_campus", hash: "#barcelona" },
          },
          {
            title: "Madrid",
            to: { name: "our_campus", hash: "#madrid" },
          },
        ],
      },
      // {
      //   image: "home-tabs-3",
      //   title: "Pathway",
      //   subtitle:
      //     'Discover your pathway to <span class="text-secondary">success</span>',
      //   links: [
      //     {
      //       title: "Learn more",
      //       to: { name: "pathway" },
      //     },
      //   ],
      // },
      // {
      //   image: "home-tabs-4",
      //   title: "Packages",
      //   subtitle:
      //     'Upgrade your language learning <span class="text-secondary">experience</span>',
      //   links: [
      //     {
      //       title: "Choose your package",
      //       to: { name: "our_packages" },
      //     },
      //   ],
      // },
      {
        image: "home-tabs-5",
        title: "Extra activities",
        subtitle:
          'Live the complete experience of the <span class="text-secondary">exchange</span>',
        links: [
          {
            title: "Explore our Extra Activities",
            to: { name: "extra_activities" },
          },
        ],
      },
    ],
    enjoy: {
      header: "Enjoy",
      subheader: 'your <span class="text-secondary">extra life</span>',
      paragraph:
        "Live the complete experience of the exchange by participating in our extra activities, meeting students from other countries, and enjoying Spain to the fullest.",
      cta: "CLICK TO KNOW MORE",
    },
    discover_campuses: {
      header: 'Discover our <span class="text-secondary">campuses</span>',
      barcelona: "BARCELONA",
      madrid: "MADRID",
    },
    testimonials: {
      what_students_say: "What our students say about us",
      paragraph:
        "Who's better to talk about our school than those who are already part of our study programs",
    },
    explore: {
      header: "Let’s explore!",
      card1: {
        header: "Know a little bit more about us!",
        body: "Let us introduce ourselves!",
      },
      card2: {
        header: "Natural Learning & Communicative Program",
        body: "Learn more about our exclusive Program",
      },
      card3: {
        header: "Student life",
        body: "We support you during all the process. At NL College, we have your back!",
      },
    },
    contact_form: {
      location: "Platja de la Barceloneta, Barcelona",
    },
    swiper_items: [
      {
        img: "home-sw-1",
        caption: `Expert instruction`,
      },
      {
        img: "home-sw-2",
        caption: `Diverse community`,
      },
      {
        img: "home-sw-3",
        caption: `Modern facilities`,
      },
      {
        img: "home-sw-4",
        caption: `Unique methodology`,
      },
      {
        img: "home-sw-5",
        caption: `Multilingual support`,
      },
      {
        img: "home-sw-6",
        caption: `Visa assistance`,
      },
      {
        img: "home-sw-7",
        caption: `Flexible scheduling and location`,
      },
    ],
    testimonial_items: [
      {
        name: "Stefanie Heyse",
        date: "March 2023",
        body: `I'm really happy to be studying Spanish at this school 
        in Barcelona. Carla, my advisor, informed me very well about 
        everything, and it was great to have her as my first point of 
        contact! The classes are structured and clear, and the teacher 
        adapts to the pace of the class and explains everything well. 
        I definitely recommend this school!`,
      },
      {
        name: "Mariana F. Miguel",
        date: "August 2019",
        body: `I loved studying at NL College! The school is very well located, the staff
        very attentive from the first contact, the facilities are new and always
        clean, and they also organize activities outside of classes so that
        students can get to know each other and also Barcelona. Very good!`,
      },
      {
        name: "Jialu Tan",
        date: "February 2022",
        body: `I don't usually leave reviews, but my teacher helped me a lot, so I
        decided to write this comment. I didn't have a basic knowledge of Spanish
        and I was afraid I wouldn't understand it before I came. I couldn't
        understand the lessons at the beginning, but the teacher tried to help me
        with various drawings, pictures and even a translator. I think it was
        easier to learn with a teacher from your own country at the beginning, but
        I chose them to learn more authentic pronunciation with the local teacher.`,
      },
      {
        name: "Alex Krasilnikov",
        date: "November 2021",
        body: `Excellent first lesson. So funny experience with international students).
        I like it!`,
      },
    ],
  },
  methodology: {
    header_title: "Our mission & methodology",
    future: {
      choose_future: 'Choose your own <span class="text-primary">future</span>',
      by_learning: "by learning a new language",
      mission_paragraph:
        "We believe that communication is the most important tool that humans can develop. Our mission is to empower our students to live, learn, and speak Spanish and English with confidence, allowing them to explore the world and express themselves clearly, knowing that they will be understood.",
      history_1:
        "Our first campus in Spain was established in Madrid in 2017 and within two years achieved the prestigious international accreditation from the Instituto Cervantes, becoming part of the network of Accredited Centers.",
      history_2:
        "In 2022, we decided it was time to expand and evolve: a new campus with incredible infrastructure was inaugurated in Barcelona to sustain this growing project, and on August 1, 2023, our second campus also obtained accreditation from the Instituto Cervantes. Our commitment to students remains the same: providing excellent teaching and ensuring they appreciate their time in Spain. We have a team of experienced teachers and native speakers, as well as multicultural support staff who will always be available to answer your questions and contribute to your well-being.",
      joining_nlcollege:
        "Joining the #NLCollegeCommunity means opening doors for your personal and professional growth, meeting people from around the world, and experiencing something unique in Madrid or Barcelona. We are here to help you make the most of your journey, and we would love for you to join our community.",
    },
    methodology_section: {
      title: "Methodology",
      description:
        "Our focus at NL College is on effective communication. Our courses are designed to equip students with the necessary skills in Spanish and English, including grammar, pronunciation, and vocabulary.",

      items: [
        {
          body: `NL College offers Spanish courses at different levels defined by the Common European Framework of Reference for Languages (
          <a
            href="https://www.coe.int/en/web/common-european-framework-reference%20languages/level-descriptions"
            target="_blank"
          >CEFR</a>
          ) and also by the
          <a
            href="https://cvc.cervantes.es/ensenanza/biblioteca_ele/plan_curricular/default.htm"
            target="_blank"
          >PCIC</a>
          (Instituto Cervantes Curriculum Plan), which develops and establishes the Reference Levels for Spanish.`,
        },
        {
          body: `You will talk Spanish from the very first day in class. NL College's
          teaching approach is based on the current philosophy of linguistic
          immersion: learning and teaching entirely in Spanish. All the textbooks and
          teaching materials are in Spanish from the very beginning.`,
        },
        {
          body: `NL College has textbooks designed to teach Spanish to foreigners. These books have been specifically selected for their modern and comprehensive methodology. They are the 'Aula Plus' series from the Difusión publishing house, internationally recognized as the most widely used Spanish manual worldwide. Each 'Aula Plus' manual is complemented with a section of exercises and formal practice, and a grammar and communication section with extensive explanations and models of verb conjugation for all tenses. The 'Aula Plus' series includes digital resources for an even more interactive experience through the Difusión Campus with audios, videos, and other materials for students.`,
        },
        {
          body: `NL College's Spanish courses are designed to follow the communicative approach, action-oriented and student-centered. Our goal is to put students at the center of the learning process, prioritize meaningful language use, and offer a modern and non-stereotyped view of Spain and Spanish-speaking countries. NL College courses are aimed at enabling clear and accessible grammatical reflection with activities for students to actively participate in their acquisition.
`,
        },
        {
          body: `NL College provides learning and communication strategies for students to reflect on their learning process, through intercultural reflection and addressing the cultural diversity of the Hispanic world. Additionally, it offers immersion activities designed to make the most of our students' stay in Spain and to learn autonomously outside the classroom.
`,
        },
        {
          body: `You will develop different language skills including comprehension, expression, interaction, and mediation, in accordance with the CEFR.
`,
        },
        {
          body: `For NL College, the quality of teaching is of utmost importance. Our teaching staff is exclusively composed of highly qualified native teachers with experience in teaching Spanish to foreigners. All our NL College teachers have a degree in Spanish language and specific training in ELE (teaching Spanish as a foreign language).`,
        },
      ],
    },
    levels: {
      title: "All about our Levels",
      description:
        "We offer a comprehensive and progressive language learning program that caters to individuals of all levels. Whether you're an absolute beginner or an advanced student, our courses are designed to help you progress from level A1, beginner, all the way up to mastery level, C2. As you complete each level, you will receive a diploma.",
      items: [
        {
          theme: "secondary",
          title: "A1: ACCESS",
          courses: ["From A1.1 to A1.2", "Official DELE A1 Diploma"],
          body: `From the first day, you will start speaking Spanish, and over the course of the two modules, you'll learn to express needs, understand and use very common everyday expressions, as well as simple phrases aimed at meeting immediate needs.`,
        },
        {
          theme: "secondary",
          title: "A2: PLATFORM",
          courses: ["From A2.1 to A2.3", "Official DELE A2 Diploma"],
          body: `You will have the ability to understand the most common expressions and sentences in the major interest areas (as personal information, describe family, and local geography)`,
        },
        {
          theme: "tertiary",
          title: "B1: Threshold",
          courses: ["From B1.1 to B1.2", "Official DELE B1 Diploma"],
          body: `You will be able to travel by yourself without language issues. You will be able to also describe events and dreams and to develop reasonable explanations, opinions and plans`,
        },
        {
          theme: "tertiary",
          title: "B2: ADVANCED",
          courses: [`From B2.1 to B2.2`, `Official DELE B2 Diploma`],
          body: `You will be able to interact with native speakers with more confidence with a considerable fluency and spontaneity and the ability to easily develop detailed texts with different subjects`,
        },
        {
          theme: "primary",
          title: "C1: Domain",
          courses: [`From C1.1 to C1.2`, `Official DELE C1 Diploma`],
          body: `You will have the ability to understand longer texts, complex conversations and can express yourself without using obvious words/expressions`,
        },
        {
          theme: "primary",
          title: "C2: MASTERY",
          courses: [`From C2.1 to C1.2`, `Official DELE C2 Diploma`],
          body: `You will be able to understand, summarise and express yourself very fluently and precisely and can easily understand everything you read or hear`,
        },
      ],
    },
    progression: {
      title: 'Know your <span class="text-primary">progression</span>',
      subtitle:
        "By tracking your progress, you can better understand how our courses are structured to help you achieve your learning goals.",
      spanish: "Spanish courses",
      english: "English courses",
      accelerated: "Accelerated",
      semi_intensive: "Semi-Intensive",
      extensive: "Extensive",
      weeks: "WEEKS",
    },
    letushelp: {
      title: "Let us help you",
      card1: {
        header: "Not sure what level you’re at?",
        body: "We have prepared a test to determine your English and Spanish proficiency levels",
      },
      card2: {
        header: "Which course will best fit your needs?",
        body: "We have options for everyone",
      },
      card3: {
        header: "Who said learning couldn’t be fun?",
        body: "We have different extra cultural activities every month. Check them out!",
      },
    },
    contact_form: {
      location: "Aerial view from Parque del Retiro, Madrid",
    },
  },
  our_program: {
    page_header: "Our program",
    section1: {
      heading: 'Know our <span class="text-primary">program</span>',
      subheading: "Natural Learning & Communicative Program",
      text1:
        "At NL College, we believe in doing things differently! Our program is exclusive and unique, based on a natural approach. Unlike traditional methods, we rely on a more immersive methodology so that our students make the most out of their stay in Spain and also learn outside the classroom. We are committed to providing our students with the best learning experience possible, and we would love to show you what sets us apart!",
      text2:
        "Through our program, students can progress through different courses and levels, empowering them to achieve their personal learning goals. Our methodology focuses on the student as the protagonist of learning, prioritizing meaningful language use. We believe in action-based learning, motivating students to fully develop their language skills.",
    },
    section2: {
      title: `A program tailored <span class="text-primary">just for you!</span>`,
      description: `At NL College, teaching and learning are based on curriculum progression where the difficulty and amount of content, as well as the number of hours, will increase as you progress from course to course and from level to level, potentially reaching an advanced level in 36 weeks. Want to know more? No other school offers such a detailed and transparent program like NL College. You'll know clearly and simply the duration of each course and level. We will inform you precisely about the grammatical, lexical, and communicative contents of each of our courses. Want to know when you'll learn the subjunctive? At NL College, we'll tell you everything.`,
      heading: "Let's <span class='text-primary'>jump in!</span>",
      items: [
        {
          titleClass: "text-secondary",
          title: `Access and Platform`,
          subtitle: "A1.1 | A1.2 | A2.1 | A2.2 | A2.3",
          body: `
          <p class="h6">A1.1</p>
        <p>Welcome to NL COLLEGE!</p>
        <p>
          What 's your name? Where are you from? In this course, you'll have
          your first contact with Spanish from the very basics, learning to
          spell your name and provide your mobile number or email address.
          You'll be able to give and ask for personal information while learning
          to conjugate regular present tense verbs. Nice to meet you!
        </p>
        <p class="h6">A1.2</p>
        <p>
          Are you interested in learning more about Spanish culture? In this course, you'll practice all irregular verbs in the present tense while explaining your daily routine. You'll also learn to talk about likes, interests, desires, and preferences (even making plans for the future) and to order tapas at a typical bar in the Gracia or Chueca neighborhoods. Enjoy your meal!
        </p>
        <p class="h6">A2.1</p>
        <p>
         Now that you know the present tense (and the near future), we want to know a bit more about you: what you look like physically, what your house is like, or what type of food you prefer. We're starting Level A2 by expanding our vocabulary and practicing everything we've learned.
        </p>
        <p class="h6">A2.2</p>
        <p>
          The next step is learning to talk about your past experiences and tell
          us a bit about your resume. We'll also teach you how to make polite
          requests and to give and understand small instructions. Live, learn,
          and speak with NL College!
        </p>
        <p class="h6">A2.3</p>
        <p>
          Did you know there are 4 official languages in Spanish? And did you
          know that in course A2.3, you'll learn to talk about habitual past
          actions and share anecdotes? We'll also organize the best plan for
          your free time by practicing the future tense. Thanks to our exclusive
          program, you have completed Stage A and are now a basic user of
          Spanish.
        </p>
          `,
        },
        {
          titleClass: "text-tertiary",
          title: `Treshhold and Advanced`,
          subtitle:
            "B1.1 (beginner and Advanced) | B1.2 (Beginner and Adanced) | B2.1 | B2.2",
          body: `
          <p class="h6">B1.1 Initial</p>
        <p>
          Congratulations! Past, present, and future: you're already halfway
          there, starting at an initial level in Stage B of your Spanish
          learning journey. Let's begin practicing more complex structures by
          talking about work or our customs. Shall we start?
        </p>
        <p class="h6">B1.1 Advanced</p>
        <p>
          You are able to speak, write, and understand at a basic level, and now
          it's time to develop your oral and written skills further. It's time
          to practice, expand your vocabulary, and learn how to give advice,
          express conditions, or understand jokes. Got it?
        </p>
        <p class="h6">B1.2 Initial</p>
        <p>
          We wish you a fantastic learning experience at NL College! So now,
          we'll introduce you to everyone's favorite among Spanish learners, our
          beloved subjunctive that all Spaniards use daily to express feelings
          when talking about others, to find your ideal job, or to express the
          cause and purpose of your Spanish learning.
        </p>
        <p class="h6">B1.2 Advanced</p>
        <p>
          You're about to finish level B1, and you can already communicate and
          understand at a medium level. You know more and more about Spain and
          other Spanish-speaking countries, and you've learned to express
          opinions, express desires, or repeat others' words. You've learned
          about ecology, social activism, or mysteries. What will happen now?
        </p>
        <p class="h6">B2.1</p>
        <p>
          It's incredible that you've been able to join the advanced course!
          Well done! In this course, you'll put into practice all the grammar
          you've learned so far and you'll be able to improve especially your
          oral expression and expand your vocabulary to a more advanced level.
        </p>
        <p class="h6">B2.2</p>
        <p>
          You're getting closer and closer to the native level, and you can already communicate in Spanish with a lot of confidence and fluency. In this course, you'll prepare yourself to enter C1 and thus become a competent user of Spanish. Your progress has been impressive, and you're very close to reaching your goal. If you want to achieve mastery level, inquire about our C1 and C2 level courses at NL College. Let's go!
        </p>
          `,
        },
        {
          titleClass: "text-primary",
          title: `Domain and Mastery`,
          subtitle: "C1.1 | C1.2 | C2.1 | C2.2",
          body: `
          <p class="h6">C1.1</p>
        <p>
          You've reached Stage C as a competent user. In this course, you'll be
          able to comprehend a wide variety of extensive texts and produce clear
          and well-structured texts. You'll be able to express yourself in a
          more fluid and spontaneous manner.
        </p>
        <p class="h6">C1.2</p>
        <p>
          Level C1 corresponds to proficient users of the language, capable of
          complex work and study tasks. Because at NL College, we're supporting
          you in achieving your goals, and the C1.2 course prepares you for
          level C2.
        </p>
        <p class="h6">C2.1</p>
        <p>
          Level C2 of Spanish is essentially native level. It will enable you to
          engage in any type of writing and reading on any topic. You'll be able
          to easily understand everything you hear or read and express yourself
          with great fluency and precision.
        </p>
        <p class="h6">C2.2</p>
        <p>
          Your journey at NL College comes to an end. It has been truly
          rewarding to have you with us and to accompany you on this great
          adventure. You have reached the Mastery level, successfully completing
          your academic program.
        </p>
          `,
        },
      ],
    },
    section3: {
      title: `We just started!`,
      card1: {
        heading: "Not sure what level you're at?",
        text: "We have prepared a test to determine your English and Spanish proficiency levels",
      },
      card2: {
        heading: "Which course will best fit your needs?",
        text: "We have options for everyone",
      },
      card3: {
        heading: "Who said learning couldn't be fun?",
        text: "We have different extra cultural activities every month. Check them out!",
      },
    },
    contact_form: {
      location: "Gaudí's Casa Batlló, Barcelona",
    },
    level_items: [
      [
        {
          color: "secondary",
          name: "A1.1",
          body: `
            <h6>Welcome to NL College!</h6>
            <p class="fw-300">
              <i>What is your name? Where are you from? Do you live near
                your NL College Campus?</i>
            </p>
            <p class="fw-300">
              In this level you will learn to give and ask for personal
              information and to conjugate the present regularly while
              shopping in a store or describing your neighborhood to us.
            </p>`,
        },

        {
          color: "secondary",
          name: "A1.2",
          body: `<p class="fw-300">
              Are you interested in learning more about Spanish culture? In this course,
              you will practice all the irregulars of the present, in addition to
              explaining your daily routine. You will also learn to talk about tastes,
              interests, desires, and preferences and make plans for the future.
              Furthermore, you will learn how to order tapas in a typical bar in the
              Gracia or Chueca neighborhoods: ¡Buen provecho!
            </p>`,
        },
      ],
      [
        {
          color: "secondary",
          name: "A2.1",
          body: `<p class="fw-300">
              Now that you know the present and the near future, you are going to learn
              to talk about your past experiences or to talk about historical events and
              to tell us a little about your resume. We start the A2 level by learning
              the past tense and also by talking about future events.
            </p>`,
        },

        {
          color: "secondary",
          name: "A2.2",
          body: `<p class="fw-300">
              Did you know that in Spain there are 4 official languages? And did you
              know that on the A2.2 level course, you will learn to talk about habitual
              actions from the past and to tell anecdotes? If you want to keep
              improving, we recommend that you study hard, come to class, and don't
              worry about anything at NL College. Thanks to our exclusive program you
              have completed stage A and you are already a basic user of Spanish.
            </p>`,
        },
      ],

      [
        {
          color: "tertiary",
          name: "B1.1",
          body: `<p class="fw-300">
            Congratulations!! You are already halfway there and you start stage B of
            your Spanish learning quite naturally. You can speak, write and understand
            at a basic level and now is the time to develop oral and written
            proficiency. It's time to practice, expand your vocabulary and learn to
            give advice, make predictions and guesses or talk about hypothetical
            situations.
          </p>`,
        },

        {
          color: "tertiary",
          name: "B1.2",
          body: `<p class="fw-300">
            We wish you a magnificent learning experience at NL College! So now
            we will introduce you to everyone's favorite Spanish topic, our beloved
            subjunctive that all Spaniards use daily to express feelings when talking
            about others, to find your ideal job, or to express the cause and purpose
            of your Spanish learning.
          </p>`,
        },
        {
          color: "tertiary",
          name: "B1.3",
          body: `<p class="fw-300">
              You are about to finish level B1 and you can already communicate and
              understand at a medium level. You are increasingly knowledgeable about
              Spain and Latin America and have learned to express opinions, apologize,
              talk about likes and dislikes, write a motivation letter, or create a
              comic strip.
            </p>`,
        },
      ],
      [
        {
          color: "tertiary",
          name: "B2.1",
          body: `<p class="fw-300">
              Incredible! you were able to join the advanced course! Well done! In this
              course, you will practice all the grammar you have learned so far and will
              be able to improve your oral expression and increase your vocabulary to a
              more advanced level.
            </p>`,
        },
        {
          color: "tertiary",
          name: "B2.2",
          body: `<p class="fw-300">
              You are getting closer to the native level and can now communicate in
              Spanish with a lot of confidence. In this course, you will prepare to
              enter C1 and become a competent user of Spanish. Your progress has been
              impressive, and you are very close to reaching your goal. If you want to
              achieve a mastery level, ask about our C1 and C2 level courses at NL
              College. Let's go!
            </p>`,
        },
      ],
    ],
  },
  meet_our_team: {
    page_header: "Meet our Team",
    section1: {
      heading: 'Nice to <span class="text-primary">meet you!</span>',
      title: "Welcome to the NL College Community!",
      body: "Our team consists of native-speaking teachers and a multicultural team dedicated to providing the best possible support to our students. Whether you need assistance before or after your arrival in Spain, we are here to help. Join us in building a strongcommunity committed to language learning excellence.",
    },
    meet_our_teachers: "Meet our <span class='text-primary'>teachers</span>",
    barcelona: {
      title: "Barcelona <span class='text-primary'>Campus</span>",
      team: [
        {
          image: "brc-team-clara",
          first_name: "clara",
          last_name: "gómez lucena",
          education: [
            "Licenciada en comunicación audiovisual",
            "Certificado de Profesor ELE, CLIC",
          ],
        },
        {
          image: "brc-team-vanessa",
          first_name: "vanessa",
          last_name: "casanova",
          education: [
            "Filología Hispánica, UB",
            "Máster oficial de profesores de ELE, UB",
          ],
        },
        {
          image: "brc-team-jone",
          first_name: "jone",
          last_name: "garcía larrañaga",
          education: [
            "Licenciatura en ciencias de la información",
            "Formación de profesores ELE, IH",
          ],
        },
        {
          image: "brc-team-ana-maria",
          first_name: "ana maria",
          last_name: "martinez ferrera",
          education: [
            "Licenciatura en Letras",
            "Maestría en LingüÍstica aplicada de ELE",
          ],
        },
        {
          image: "brc-team-mireia",
          first_name: "mireia",
          last_name: "martín franco",
          education: ["Grado en Filología Hispánica, UB", "Máster en ELE, UB"],
        },
      ],
    },
    madrid: {
      title: "Madrid <span class='text-primary'>Campus</span>",
      team: [
        {
          image: "mdr-team-desiderio",
          first_name: "Desiderio",
          last_name: "Santonja Martínez",
          education: [
            "Grado de lengua y literatura españolas",
            "Examinador DELE A1-A2/B1-B2/C1-C2",
          ],
        },
        {
          image: "mdr-team-david",
          first_name: "David",
          last_name: "Catalán Hellín",
          education: [
            "Grado en Español: Lengua y Literatura",
            "Máster en Español como Segunda Lengua",
          ],
        },
        {
          image: "mdr-team-isabel",
          first_name: "Isabel",
          last_name: "Lucas Navalón",
          education: [
            "Licenciada en Filología francesa y CAP",
            "Curso examinador DELE C1/C2",
          ],
        },
        {
          image: "mdr-team-fatima",
          first_name: "Fátima",
          last_name: "Herranz Díaz",
          education: ["Máster en Enseñanza de español para extranjeros"],
        },
        {
          image: "mdr-team-ruben",
          first_name: "Rubén",
          last_name: "Guerra Corral",
          education: [
            "Máster en Lingüística y ELE",
            "Curso de español con fines específicos",
          ],
        },
      ],
    },
    section_help: {
      header: "Know more about us!",
      card1: {
        title: "Do you want to know more about Barcelona?",
        body: "Discover our Barcelona Campus",
      },
      card2: {
        title: "Do you want to know more about Madrid?",
        body: "Discover our Madrid Campus",
      },
      card3: {
        title: "Which course will best fit your needs?",
        body: "Are you in a rush or do you plan a long stay? We have options for everyone",
      },
    },
    contact_form: {
      location: "El Oso y el Madroño sculpture, Plaza del Sol, Madrid",
    },
  },
  faqs: {
    header: "Frequently Asked Questions",
    section1: {
      heading: 'Got any <span class="text-primary">questions?</span>',
      paragraph1:
        "We understand that embarking on a new journey can be overwhelming, but don't worry, we are here to help. Our team has a wealth of knowledge and experience in guiding students like you through any questions or inquiries you may have.",
      paragraph2:
        "We will be happy to provide you with the answers you need. Our goal is to ensure that your time at NL College is as smooth and stress-free as possible.",
      paragraph3:
        "If you are unsure where to start, we have put together a list of frequently asked questions that may be of assistance to you. But, if you need further clarification or have additional questions, please don't hesitate to reach out to us. We are here to make sure you have a successful and enjoyable experience with us.",
    },
    contact_form: {
      location: "Gothic quarter, Barcelona",
    },
    faq_sections: [
      {
        title: "Courses",
        items: [
          {
            subtitle: "Which courses are offered?",
            body: `
              <p>
                We offer a variety of language classes to fit your needs, including Spanish
                and English.
              </p>

              <p>
                Our Spanish courses are:
              </p>

              <ul  class="disc-style">
                <li>
                  Accelerated with 20 hours per week (program that meets visa requirements)
                </li>
<li>
                  Intensive 10 + 10 (program that meets visa requirements)
                </li>
                <li>
                  Semi Intensive with 10 hours per week.
                </li>
                <li>
                  Extensive with 4 hours per week
                </li>
<li>
                  Conversation classes
                </li>
<li>
                  Business classes
                </li>
<li>
                  Packages: Accelerated Plus 25 h and Accelerated Premium Plus 30 h</li>
<li>
                  Private classes
                </li>
<li>
                  Online classes
                </li>
                <li>
                  Exam preparation courses: DELE, SIELE, and CCSE
                </li>
                
              </ul>

              <p>
                For our English courses we have:
              </p>

              <ul>      
                <li>
                  Extensive with 4 hours per week on either Mondays and Wednesdays or
                  Tuesdays and Thursdays.
                </li>
                <li>
                  Cambridge preparation course
                </li>
                <li>
                  Private classes
                </li>
<li>
                  Online classes
                </li>
              </ul>

              <p>
                We understand that your time is valuable, which is why we offer a range of
                class options to fit your schedule. Don't hesitate to reach out to us for
                more information on our courses and timetables.
              </p>`,
          },
          {
            subtitle: "When can I start studying?",
            body: `
              <p>
                You can join our classes every monday. To ensure that you're placed in a class that matches your skill level, we require all new students to complete a placement test. Once you've completed the test, you can choose the course that best fits your schedule and start learning right away. 
              </p>`,
          },
          {
            subtitle: "How do I know my level?",
            body: `<p>If you have already spoken to one of our agents, they will provide you with a link to our placement test. If you haven't spoken to an agent yet, don't worry. You can easily find the test on our website.</p>
              <p>Our placement test is designed to accurately assess your language skills and place you in a class that matches your proficiency level. This ensures that you get the most out of your language learning experience and can progress at a pace that suits you.</p>
              <p>So, if you're ready to discover your language level and start learning with NL College, take our placement test today.</p>`,
          },
          {
            subtitle: "How many levels are there?",
            body: `<p>We offer language courses for all proficiency levels, from A1 to C2. Whether you're a beginner looking to start from scratch or an advanced learner hoping to refine your skills, we have a course that's right for you.</p>
              <p>Join our community of passionate learners and start your language learning journey with NL College today.</p>`,
          },
          {
            subtitle: "How long does one level take to complete?",
            body: `
              <p>
                We understand that each student has their own unique learning pace and goals.
                As such, the time it takes to complete one level of our language courses can
                vary depending on the specific course you choose
              </p>

              <table>
                <tr>
                  <th></th>
                  <th>A1</th>
                  <th>A2</th>
                  <th>B1</th>
                  <th>B2</th>
                  <th>C1</th>
                  <th>C2</th>
                </tr>
                <tr>
                  <th>Accelerated</th>
                  <td>4 weeks</td>
                  <td>4 weeks</td>
                  <td>6 weeks</td>
                  <td>24 weeks</td>
                  <td>26 weeks</td>
                  <td>26 weeks</td>
                </tr>
                <tr>
                  <th>Semi-Intensive</th>
                  <td>8 weeks</td>
                  <td>8 weeks</td>
                  <td>12 weeks</td>
                  <td>48 weeks</td>
                  <td>52 weeks</td>
                  <td>52 weeks</td>
                </tr>
                <tr>
                  <th>Extensive</th>
                  <td>20 weeks</td>
                  <td>20 weeks</td>
                  <td>30 weeks</td>
                  <td>120 weeks</td>
                  <td>130 weeks</td>
                  <td>130 weeks</td>
                </tr>
              </table>

              <p>
                So, if you're ready to start your language learning journey, contact us today
                to learn more about our courses and find the one that's right for you.
              </p>
            `,
          },
          {
            subtitle:
              "How long do I have to start my course once it has been paid for?",
            body: `
<p>We understand that life can be unpredictable, and you may need flexibility when it comes to starting your language course. That's why we offer our students the option to start their course up to 12 months after it has been paid for.</p>

<p>So, if you're considering enrolling in a language course with NL College, you can do so with confidence, knowing that you'll have the flexibility and support you need to succeed. Contact us today to learn more about our courses and start planning your language learning journey.</p>
            `,
          },
          {
            subtitle: "What kinds of long-term packages do you offer?",
            body: `
<p>We offer a range of language courses to meet the needs of our students, including long-term packages for those who want to commit to a more extensive period of study.</p>

<p>Our courses start from one week and can last up to one year, with long-term packages typically considered to be those that involve more than six months of study. These packages are designed to provide our students with the time and support they need to fully immerse themselves in the language and make significant progress towards fluency.</p>

<p>So, if you're ready to commit to a long-term language learning journey, contact us today to learn more about our packages and find the one that's right for you.</p>
            `,
          },
          {
            subtitle: "Are books mandatory?",
            body: `
<p>To ensure the best learning experience, it is mandatory to have the required books for your classes. It's important to keep in mind that as you progress to new levels, new materials will be necessary. Don't worry if your course package doesn't include the books; you can easily purchase them at our reception. Our team will be happy to assist you in acquiring the required materials so you can focus on achieving your academic goals.</p>
            `,
          },
          {
            subtitle: "Is a trial class offered?",
            body: `
<p>Yes. We offer free trials because we know that choosing the right language school is an important decision, and you want to make sure you feel comfortable and confident in your choice. That's why we offer our prospective students the opportunity to take part in a trial class.</p>

<p>During the trial class, which is free of charge and lasts for two hours, you'll have the chance to experience our teaching methods and get a sense of the classroom environment. This will give you a better understanding of what it's like to learn with us and help you decide if NL College is the right fit for you.</p>

<p>To book your trial class, simply let your agent know that you're interested, and they'll sign you up. We're confident that you'll love our courses and see the value in learning with us, so don't hesitate to reach out and take advantage of this opportunity. Contact us today to learn more and book your trial class according to our availability. </p>
            `,
          },
          {
            subtitle: "What is the total number of students in each class?",
            body: `<p>We believe in providing our students with an intimate and engaging learning environment. That's why we limit the number of students in each class to a maximum of 10. This ensures that you receive personalised attention from your teacher, can actively participate in class discussions, and have plenty of opportunities to practise your language skills.</p>

<p>So, if you're looking for a language school that prioritises individualised attention and a school that has a supportive atmosphere, look no further than NL College.</p>`,
          },
          {
            subtitle:
              "Are the classes composed of students from different nationalities?",
            body: `<p>We pride ourselves on providing a truly international environment for our students. Our classes typically consist of students from all over the world.  We welcome students from a wide range of countries, and some of the most common nationalities represented in our classes include: Sweden, the United Kingdom, Germany, Italy, China, , Brazil, South Africa, France, Japan, the United States, and Canada, to name just a few.</p>

<p>Our diverse student body adds to the rich cultural experience of learning a language, and we believe that interacting with people from different backgrounds and cultures is an essential part of becoming a proficient language speaker.</p>

<p>So if you're looking for an opportunity to learn a language while immersing yourself in a truly global environment, look no further than NL College. Contact us today to learn more and begin your language learning journey with us.</p>`,
          },
          {
            subtitle: "What is the methodology of the courses?",
            body: `<p>At NL College, we believe that effective communication is the cornerstone of language learning. Our courses are designed to help students communicate with confidence, using the grammar, pronunciation, and vocabulary that they learn from us. We follow the communicative, action-oriented, student-centred approach, which emphasises active participation and learning by doing.</p>

<p>Our courses are aligned with the CEFR (Common European Framework of Reference for Languages) and the PCIC (Curriculum Plan of the INSTITUTO CERVANTES), ensuring that our students receive the highest quality language education. Throughout the course, our experienced teachers provide clear and accessible grammatical reflection with engaging activities that encourage students to actively participate in their own learning.</p>

<p>We believe that speaking from the very beginning is key to language acquisition, and we encourage our students to do so. Let us help you achieve your language goals through our dynamic, student-centred methodology!</p>`,
          },
          {
            subtitle: "Can I renew my course?",
            body: `<p>Yes, you can. To renew your course, simply reach out to the agent you originally spoke with when you joined NL College, and continue your language learning with us. Don't hesitate to reach out to us today, we will be happy to answer any questions you may have and guide you through the enrollment process.</p>`,
          },
          {
            subtitle:
              "I still have doubts about what is offered, what should I do?",
            body: `<p>We understand that you may have some questions or concerns about our offerings, and we're here to help! Please don't hesitate to reach out to our knowledgeable agents, who are ready to provide you with all the information you need about our courses and what it's like to live in Spain.</p>

<p> We're committed to ensuring that you have all the information you need to make an informed decision about your education, so please don't hesitate to contact us!</p>`,
          },
        ],
      },
      {
        title: "Accommodation",
        items: [
          {
            subtitle: "Does NL College offer accommodation?",
            body: `<p>Absolutely! We know that moving to a foreign country can be a daunting experience, which is why we offer a range of accommodation options through our partners to make your transition as seamless and stress-free as possible.</p>

<p>Our network of accommodation partners ensures that you'll be placed in a safe and comfortable location that's conveniently located near the school. Of course, there is an additional cost for this service, but we believe that the peace of mind and convenience that it provides is well worth the investment.</p>

<p>Let us help you make the most of your time at NL College by taking care of your accommodation needs!</p>`,
          },
          {
            subtitle: "Which type of accommodation do you offer? ",
            body: `<p>We offer through our partners a variety of accommodation options to suit your needs and preferences. You can choose from a shared apartment, student residence, or hostel, depending on your budget and what works best for you.</p>

<p> Each option is designed to provide you with a comfortable and convenient living space that allows you to focus on your studies and enjoy your time in our vibrant community.</p>

<p>Let us know which option you prefer and we'll do everything we can to make sure you feel at home with us!</p>`,
          },
          {
            subtitle:
              "If my course does not include accommodation, can I pay for it as an extra?",
            body: `<p>Great news! Yes, you have the option to add accommodation to your course package as an extra. Simply get in touch with the agent you initially communicated with when you enrolled and they'll be happy to assist you.</p>`,
          },
          {
            subtitle:
              "How much does it cost to live monthly in Barcelona or Madrid?",
            body: `<p>Keep in mind that costs can vary depending on several factors, such as your accommodation and lifestyle.</p>

<p>In Barcelona, for instance, a private room in a shared apartment can cost you around 350&euro; to 600&euro; per month. You'll need to budget an additional 40&euro; for a monthly transport card and 50-100&euro; for utilities such as electricity, water, and gas.</p>

<p>In Madrid, a private room in a shared apartment is typically priced between 300&euro; to 500&euro; per month. The monthly transport card costs around 20&euro;, and you'll need to allocate 50&euro;-100&euro; for utilities.</p>

<p>For your communication and entertainment needs, a prepaid phone package typically costs 15&euro; for 28 days, and a monthly average Fibra 600Mbps WIFI package costs around 33&euro;.</p>

<p>As for groceries, the basic food basket is used as a reference for supermarket consumption in Spain, and it typically costs 101&euro; per month. Keep in mind that individual consumption habits may affect your total supermarket bill.</p>

<p> This information is based on the Numbeo website. Check public transport prices for Madrid at crtm.es and Barcelona tmb.cat</p>`,
          },
        ],
      },
      {
        title: "Payment",
        items: [
          {
            subtitle: "How can I pay for my course?",
            body: `<p>At our school, we believe that paying for your course should be simple and hassle-free. That's why we offer you three convenient ways to make your payment.</p>

<p>Firstly, you can pay for your course via bank transfer, which is a safe and reliable method that many of our students prefer. This option allows you to transfer funds directly to our school account, giving you the peace of mind that your payment has been received and processed securely.</p>

<p>Alternatively, we offer an online payment tool through our trusted partner Flywire. This platform allows you to make your payment quickly and easily, using a range of payment methods that are convenient for you.</p>

<p>Finally, for those who prefer a more traditional approach, we also accept cash or card payments in person at our school reception, where our friendly staff will be happy to assist you with any questions you may have and ensure that your payment is processed promptly. To ensure that your payment process goes smoothly, we kindly request that you let your agent know in advance that you plan to pay in person. This will allow us to prepare your invoice ahead of time and ensure that we have all the necessary information to process your payment quickly and accurately.</p>

<p>Whatever your preferred method of payment, we are committed to making your enrollment as smooth and stress-free as possible. So why wait? Choose the option that works best for you and take the first step towards your educational goals today.</p>`,
          },
          {
            subtitle: "Is it necessary for me to pay before the course begins?",
            body: `<p>At our school, we require payment in advance for the weeks that you would like to reserve your place on the course. This ensures that we can secure your enrollment and guarantee your seat in the class.</p>

<p>Once your payment has been approved and processed, you will be able to attend your course with confidence, knowing that your place has been reserved and your enrollment is confirmed.</p>

<p>So if you're ready to start your educational journey with us, we encourage you to make your payment in advance and reserve your place on the course. Our team is standing by to assist you with any questions you may have and ensure that your enrollment process is smooth and hassle-free.</p>`,
          },
          {
            subtitle: "Do you have promotional prices?",
            body: `<p>At our school, we believe that education should be accessible to everyone, regardless of their financial circumstances. That's why we are committed to providing you with the most affordable pricing options and exceptional value for your investment.</p>

<p> So don't wait &ndash; ask the agent you are speaking with for our promotional pricing today and take the first step towards achieving your educational goals.</p>`,
          },
        ],
      },
      {
        title: "VISA",
        items: [
          {
            subtitle: "Do I need a student visa to study in Spain?",
            body: `<p>If you are not an EU citizen, then you will need a student visa to study in Spain. To study, you must apply for a stay authorization specifically for educational purposes. However, if you are from the European Union, you do not need a student visa because it is not required</p>`,
          },
          {
            subtitle: "What type of visa do I need?",
            body: `<p>We offer three different options depending on the duration of your program.</p>

<p>Tourist visa up to 90 days, C category</p>

<p>For those studying a course shorter than 90 days, the Tourist Visa is the perfect choice. International students from countries other than EU/EEA member states and Switzerland are required to apply for a Schengen Student Visa. However, if you're from a country that doesn't require a visa to travel to Spain for up to 90 days, you're eligible to study in Spain without applying for a visa.</p>

<p>Students visa up to 180 days D close category</p>

<p>If you're planning to study for 3-6 months, our short-term student visa, the 180-days D close type visa, is the best option. It's easier to apply for and cannot be extended. However, if your course lasts longer than 180 days, you'll need to apply for a long-term student visa.</p>

<p>Students visa up to 180 days D open category</p>

<p>For those seeking to undertake courses longer than 27 weeks, our long-term student visa, the D open category, allows you to obtain a Residence Card (NIE) and prolong your studies from within Spain after your first visa expires.</p>

<p>Don't let the visa application process hold you back from achieving your educational dreams in Spain. Contact us today to learn more about our course options!</p>`,
          },
          {
            subtitle: "Does the school help me to obtain a student visa?",
            body: `<p>We are always by your side! The entire visa application procedure could be difficult and time-consuming for non-EU citizens. However, you don't need to worry. NL College and the lawyer office we work with will help you! We have rich experience in assisting non-EU students with their long-term student visas.</p>

<p> Our multilingual team is ready to guide you in this very important step of your student exchange. We are available to answer any questions and the school will provide all the necessary documentation regarding your studies.</p>

<p> Our long-term programs include the official school documents and certificates you'll need to apply for a student visa. We will help you to fulfil your dream of studying abroad in Spain! Start your NL College experience now!</p>

<p>We also have a partnership with a law firm in Spain (Solicitor) that offers support as well for an additional cost with your visa application procedure and also with settling down visa packages in Spain.</p>

<p>The first step is to enrol in your Spanish course at NL College, once you have your course fully paid you can start the visa process</p>`,
          },
          {
            subtitle:
              "Which course do I need to purchase if I want a long term visa?",
            body: `<p>To obtain a long-term visa, you'll need to purchase a course that is more than 6 months in duration. And, if you plan to extend your stay in Spain after your studies, you should choose a course that lasts more than 27 weeks.</p>

<p>Our team of agents is available to assist you in finding the perfect course at the best price. So why wait? Contact us now and start your journey towards a fulfilling educational experience in Spain!</p>`,
          },
          {
            subtitle: "When I have my student visa, what’s next?",
            body: `<p>Once you have your long-term visa Type D in your passport and have arrived in Spain, you may now apply for your NIE card.</p>`,
          },
          {
            subtitle: "What is the NIE number and how can I get it?",
            body: `<p>Your NIE (N&uacute;mero de Identificaci&oacute;n de Extranjero) is the identification number which you will already have in the visa. It is a critical piece of documentation that identifies you as a foreigner living in Spain. It is essential for opening bank accounts, signing contracts, and carrying out many other important activities in the country.</p>

<p>To obtain your NIE card, you will need to book an appointment at the nearest police station or foreigner's office. During your appointment, you will need to provide your passport, your visa, and proof of your address in Spain. Once your application is processed, you will receive your NIE card, which will be valid for the duration of your stay in the country.</p>`,
          },
          {
            subtitle: "What is TIE,  and how can I get it?",
            body: `<p>
  The TIE is the physical card that also includes your NIE on it. To obtain your
  first ever TIE card, you need the following:
</p>

<ul class="disc-style">
  <li>Original passport and copy</li>
  <li>Visa page copy</li>
  <li>Admission letter from the school</li>
  <li>Passport size 1 picture with white background</li>
  <li>EX 17 form</li>
  <li>Home registration certificate (Empadronamiento)</li>
</ul>

<p>
  After applying for it, it takes around 30 to 45 days in order to be ready.
</p>
`,
          },
          {
            subtitle: "Can I extend my student visa from Spain?",
            body: `<p>Great news! If you're a student currently studying in Spain, you can extend your student visa without having to leave the country. This means you can continue to pursue your academic goals and enjoy everything Spain has to offer, without any interruptions.</p>

<p>To renew your student visa, simply apply for an extension no later than 60 days before the expiration date of your current stay status. You can also apply for an extension up to 90 days after the expiration date, so there's no need to panic if you've missed the initial deadline.</p>

<p>With your renewed student visa, you'll be able to stay in Spain for an extended period of time, giving you more time to immerse yourself in the local culture, learn the language, and achieve your academic goals. So don't let your student visa expire - apply for an extension today and keep your Spanish adventure going! </p>`,
          },
          {
            subtitle: "I entered Spain as a tourist, can I regularise my visa?",
            body: `<p>Are you currently in Spain as a tourist, but want to stay longer to pursue your academic goals? We've got great news for you! You can regularise your visa by applying for a student residence permit, right here in Spain.</p>

<p> To apply for a student residence permit (estancia por estudios), make sure you have at least 60 days of legal stay left in Spain. Our lawyer office partner can guide you through the entire process, ensuring that your application is completed quickly and accurately.</p>

<p> By obtaining a student residence permit, you'll be able to stay in Spain legally for the duration of your studies. Don't let your tourist status hold you back from achieving your academic dreams in Spain. </p>`,
          },
          {
            subtitle:
              "What documents am I required to submit while applying for a student visa?",
            body: `<p>From NL College We'll provide you with a Registration Letter, Invitation Letter and Studies Programme. These are the official documents that outline the details of your course and confirm that you've been accepted as a student at our college.</p>

<p> Our team is always here to answer any questions you may have and provide you with the support you need to make your academic dreams a reality.</p>`,
          },
          {
            subtitle: "What if my visa is denied?",
            body: `<p>At NL College, we understand that visa denials can be stressful and frustrating. That's why we're here to help you navigate the process and explore your options.</p>

<p>If your visa application has been denied, don't worry - we've got you covered. All you need to do is inform us before the start date of your course and we can postpone your starting day.</p>

<p>Alternatively, if you decide not to reapply for your visa, you can request a refund or credit note for your course. Simply send an email to admisiones@nlcollege.es with a copy of your passport and the official visa denial letter issued, signed, and stamped by the corresponding embassy. Make sure to send it within the specified deadlines to ensure that we can process your request promptly.</p>`,
          },
        ],
      },
    ],
  },
  work_with_us: {
    title: "Work with us",
    section1: {
      heading: "We're <span class='text-primary'>hiring!</span>",
      paragraph1:
        "Are you looking for new opportunities? We're always looking for new talent. If you're a professional in the Teaching, Administration or Marketing field, come and say Hi!",
      btn: "Our current job vacancies",
    },
    section2: {
      title: "How to apply",
      steps: [
        "Fill the form below",
        'Send us your CV at <a class="text-primary" href="mailto:people@nlcollege.es">people@nlcollege.es</a>',
        "Apply via LinkedIn",
      ],
    },
    card: {
      heading: "Are you interested in knowing more about our school?",
      description: "Discover our two Campuses",
    },
    form: {
      title: "Your details",
    },
  },
  our_campus: {
    title: "Our Campuses",
    section1: {
      heading: 'Welcome to our <span class="text-tertiary">school</span>',
      barcelona: "BARCELONA",
      madrid: "MADRID",
      paragraph1:
        "We take immense pride in offering our students the opportunity to experience the best of Madrid and Barcelona. Our strategically located campuses in these vibrant cities enable our students to immerse themselves in the local culture, and create unforgettable memories.",
      paragraph2:
        "Located in the heart of Madrid's iconic Puerta del Sol neighbourhood, our campus is a mere stone's throw away from the metro station, making it easily accessible via public transportation. Not to mention, it's in close proximity to popular attractions such as Plaza Mayor and the Museo Nacional del Prado.",
      paragraph3:
        "In Barcelona, our unit in the charming Sarrià, district of Sant Gervasi, is just a short 2-minute walk from FGC Pl. Molina station and 6 minutes from Fontana metro station. The neighbourhood boasts easy access to the city's main attractions, including the world-renowned Park Güell.",
      paragraph4:
        "At our school, we believe that learning Spanish is not just about language acquisition, but also about experiencing the rich culture of Spain. That's why we offer a plethora of extra activities and excursions that allow our students to dive deeper into the local celebrations, culture, gastronomy, and more. Join us on this unforgettable journey of language learning and cultural immersion!",
    },
    barcelona: {
      title: 'Barcelona <br /><span class="text-tertiary">campus</span>',
      header: "¡Barcelona es especial!",
      subheader:
        "Studying in Barcelona presents a unique opportunity to explore a vibrant city bursting with color and boasting an exhilarating nightlife",
      paragraph1:
        "The city is a cultural hub, offering a rich tapestry of sport, history, art, and gastronomy. From Gaudí's architectural marvels to the charming Gothic Quarter, every corner of Barcelona is sure to captivate you. What's more, with a growing demand for companies, especially in the digital realm, the city has become an important networking point.",
      paragraph2:
        "Our campus is located in one of the best neighborhoods of the city, just a short 5-minute walk from the metro station. Our modern, 5 floor building features a terrace with breathtaking views, 13 classrooms, and a student lounge, creating a dynamic and immersive environment to experience Spanish culture. Our facilities are designed to facilitate our students' learning and make their experience with us truly unforgettable.",
      paragraph3:
        "At our campus, we believe that the quality of teaching is just as important as the overall student experience. That's why our classes are limited to a maximum of 12 students per class, ensuring personalized attention from our native and specialized teachers. We offer a range of Spanish and English courses to meet all your needs, both in and out of the classroom. Join us on this exciting journey of language learning and cultural immersion!",
      information: {
        title: "What you need to know",
        card1: {
          title: "Barcelona has it all",
          body: "Sport, history, art, architecture, beautiful beaches, incomparable weather and unique gastronomy",
        },
        card2: {
          title: "Great location",
          body: "<li>2-minute walk from FGC Pl. Molina station</li> <li>5 minutes from Fontana metro station</li>",
        },
        card3: {
          title: "Modern, spacious installations",
          body: "Enjoy your lesson in a spacious, bright classroom. Maximum students per class: 12",
        },
      },
      slider: [
        {
          img: "slide_bcn_01",
          caption: "New and modern facilities",
        },
        {
          img: "slide_bcn_02",
          caption: "Diverse community",
        },
        {
          img: "slide_bcn_03",
          caption: "Cozy student lounge",
        },
        {
          img: "slide_bcn_04",
          caption: "Weekly extra activities",
        },
        {
          img: "slide_bcn_05",
          caption: "Free Wi-fi",
        },
      ],
    },
    madrid: {
      title: 'Madrid <br /><span class="text-secondary">campus</span>',
      paragraph1:
        "With excellent subway transport links and an international airport serving 154 different destinations, the city is easily accessible from all over the world. From the stunning Parque del Retiro to one of the world's best football clubs, Madrid has something for everyone.",
      paragraph2:
        "At our school, we are committed to providing you with an unforgettable experience in Spain, whether that means improving your language skills or immersing yourself in all that the country has to offer. Since 2017, we have been offering quality teaching with native teachers and students from all over the globe.",
      paragraph3:
        "When you study at our NL College Madrid Campus, you'll find yourself in one of the city's most iconic landmarks, the Puerta del Sol, right in the heart of the city. Everything you could possibly need is right at your fingertips, from convenient public transport links to stunning sights, delicious food, and a diverse community of people from around the world. Join us on this exciting journey of cultural exploration and language learning!",
      header: "De Madrid <br /> al cielo",
      subheader:
        "As the capital of Spain and the third largest city in the European Union, Madrid is a bustling hub of activity and culture.",
      information: {
        title: "What you need to know",
        card1: {
          title: "From Madrid to the sky?",
          body: "Do you know why they say ‘De Madrid al cielo’? Because, after Madrid, the only better thing is, well, the sky!",
        },
        card2: {
          title: "Great location",
          body: "The school is located rght in the heart of Madrid. From Puerta del Sol, you’ll be able to get to wherever you want",
        },
        card3: {
          title: "5+ years of experience",
          body: "Since 2017, we have been offering quality teaching with native teachers and students from all over the globe",
        },
      },
      slider: [
        {
          img: "slide_mad_01",
          caption: "New and modern facilities",
        },
        {
          img: "slide_mad_02",
          caption: "Diverse community",
        },
        {
          img: "slide_mad_03",
          caption: "Cozy student lounge",
        },
        {
          img: "slide_mad_04",
          caption: "Weekly extra activities",
        },
        {
          img: "slide_bcn_05",
          caption: "Free Wi-fi",
        },
      ],
    },
    accreditations: {
      title: "Accreditations",
      cervantes: "Accredited by the Cervantes Institute",
      dele_siele: "Official  examination center of:",
      cambridge: "Authorised Exam Centre",
    },

    links: {
      title: "But wait, there's more!",
      card1: {
        title: "Meet your teachers",
        body: "Know a little bit more about our team",
      },
      card2: {
        title: "Which course will best fit your needs?",
        body: "We have options for everyone",
      },
      card3: {
        title: "Who said learning couldn't be fun?",
        body: "We have different extra cultural activities every month. Check them out!",
      },
    },
    contact_form: {
      location: "Architecture at Passeig de Gràcia, Barcelona",
    },
  },
  our_courses: {
    title: "Our Courses",
    section1: {
      header: 'The time to learn is <span class="text-tertiary">now</span>',
      spanish: "Spanish",
      english: "English",
      dele_siele: "DELE, CCSE & SIELE preparation",
      paragraph1:
        "Thank you for considering NL College for your language learning adventure. We understand that every student has unique needs and goals when it comes to language learning. That's why we offer a range of Spanish and English courses to cater to your individual needs.",
      paragraph2:
        "Our courses are designed to meet the needs of individuals with varying proficiency levels, ranging from A1 (basic) to C2 (proficiency). If you're already familiar with the language, you're welcome to join any of our existing groups, which start every Monday. However, if you're just starting, no worries at all. We have multiple starting dates available that you can check out <a download target='_blank'  href='/files/CALENDARS_2023.pdf'>here.</a>",
      paragraph3:
        "Our team of dedicated language experts is committed to delivering the best language education to our students. In addition to our regular courses, we also offer exam preparation courses that will help you excel in your language exams.",
      paragraph4:
        "At NL College, we understand that language learning can be challenging, which is why we provide support every step of the way. Our aim is to help you achieve your language goals and ensure that your language learning journey is both enjoyable and successful.",
    },
    spanish_courses: {
      header: '<span class="text-tertiary">Spanish</span> <br />courses',
      information: {
        header: "¡Hola!, ¿Empezamos?",
        paragraph1:
          "Experience the beauty of the Spanish language in two of the world's most impressive cities: Barcelona and Madrid.",
        paragraph2:
          "Our Spanish courses for adults cater to all needs and schedules, ensuring you achieve your personal learning goals in a friendly and professional environment. At NL College, we understand that everyone learns differently, which is why we offer a program designed for each student profile.",
        paragraph3:
          "Explore our range of courses and find the perfect fit for your individual needs. Learn at your own pace and let us help you achieve your language learning goals.",
      },
      buttons: [
        {
          anchor: "#es-courses",
          title: "Courses",
        },
        {
          anchor: "#es-packages",
          title: "Packages",
        },
        {
          anchor: "#es-private-classes",
          title: "Private Classes",
        },
        {
          anchor: "#es-online-classes",
          title: "Online Classess",
        },
        {
          anchor: "#es-preparation-courses",
          title: "Preparation Courses",
        },
      ],
      sections: [
        {
          anchor: "es-courses",
          title: "Courses",
          items: [
            {
              title: "ACCELERATED",
              visa_requirements:
                "This course meets the long-term VISA requirements",
              check_prices: "Check our price list",
              cta: "I WANT TO KNOW MORE",
              table: {
                schedule: {
                  title: "Schedule",
                  hours: "20h per week",
                  days: "4 hours / day",
                },
                schedules: [
                  {
                    items: [
                      {
                        title: "Barcelona",
                        hours: "9:30 - 1:30 pm",
                        days: "Mon to Fri*",
                      },
                      {
                        hours: "2:00 - 6:00 pm",
                        days: "Mon to Fri*",
                        with_border: true,
                      },
                      {
                        title: "Madrid",
                        hours: "9:00 - 1:00 pm",
                        days: "Mon to Fri*",
                      },
                      {
                        hours: "1:30 - 5:30 pm",
                        days: "Mon to Fri*",
                      },
                    ],
                  },
                ],
                calendar: {
                  text: "See our calendar for start dates",
                  link: "/files/A3_CALENDAR_nlcollege_2024_both.pdf",
                },
                levels: {
                  title: "Levels",
                  desc: "From A1 to C1",
                },
                prices: {
                  title: "Price / month",
                  desc: ["€ 634"],
                  msg: "Remember: the more weeks you choose, the more discount you get!",
                },
                depending: "* depending on availability",
              },
              description: [
                {
                  subtitle: "Looking to learn Spanish at lightning speed?",
                  body: `
                    <p>
                      Our Accelerated Spanish Course is the perfect solution! This
                      highly acclaimed course is specially designed for long-term
                      students who need to meet visa requirements, but also want to
                      improve their communication, comprehension, writing, and
                      grammatical skills in a short period of time.
                    </p>

                    <p>
                      With our Accelerated Spanish Course, you will see immediate
                      results and develop your communicative abilities in no time. Our
                      expert teachers will guide you through daily oral expression and
                      written comprehension exercises, ensuring that your exposure to
                      the language is abundant.
                    </p>

                    <p>
                      The class is split into two parts, with a different native teacher
                      for each slot and a short break in between. This means that you
                      will enjoy two unique teaching styles and benefit from the
                      expertise of two magnificent professors from the NL College
                      teaching team. Join us today and accelerate your Spanish learning
                      journey!
                    </p>`,
                },
              ],
            },
            {
              title: "INTENSIVE (10 + 10)",
              visa_requirements:
                "This course meets the long-term VISA requirements",
              check_prices: "Check our price list",
              cta: "I WANT TO KNOW MORE",
              table: {
                schedule: {
                  title: "Schedule",
                  hours: "20h per week",
                  days: "4 hours / day",
                },
                schedules: [
                  {
                    items: [
                      {
                        title: "Barcelona",
                        hours: "2:00 - 4:00 pm",
                        days: "Mon to Fri*",
                      },
                      {
                        hours: "4:00 - 6:00 pm",
                        days: "Mon to Fri*",
                        with_border: true,
                      },
                      {
                        title: "Madrid",
                        hours: "1:30 - 3:30 pm",
                        days: "Mon to Fri*",
                      },
                      {
                        hours: "4:30 - 5:30 pm",
                        days: "Mon to Fri*",
                      },
                    ],
                  },
                ],
                calendar: {
                  text: "See our calendar for start dates",
                  link: "/files/A3_CALENDAR_nlcollege_2024_both.pdf",
                },
                levels: {
                  title: "Levels",
                  desc: "From A2 to C2",
                },
                prices: {
                  title: "Price / month",
                  desc: ["€ 568"],
                  msg: "Remember: the more weeks you choose, the more discount you get!",
                },
                depending: "* depending on availability",
              },
              description: [
                {
                  subtitle: "Maximize your mornings with our Intensive Course",
                  body: `
                    <p>
                      At NL College, we offer an intensive Spanish course in Barcelona and Madrid,
                      ideal for long-term students starting from level A2, and it meets the
                      requirements for applying for a student visa in Spain.
                    </p>
                    <p>
                      This program allows for free time in the mornings, maximizing the cultural
                      experience in Spain. The classes follow a communicative, student-centered
                      approach and are designed to develop comprehensive language skills:
                      comprehension, expression, interaction, and mediation, according to the CEFR.
                    </p>
                    <p>
                      In our intensive afternoon course program, students study 10 hours of general
                      Spanish each week and supplement it with 10 optional hours: a 5-hour
                      conversation course and a 5-hour business Spanish course. This allows time to
                      cover all aspects of the Spanish language each week and progress from course
                      to course, level to level, without rushing, while students improve their oral
                      expression and invest in their professional future.
                    </p>`,
                },
              ],
            },
            {
              title: "SEMI-INTENSIVE",
              visa_requirements: null,
              check_prices: "Check our price list",
              cta: "I WANT TO KNOW MORE",
              table: {
                schedule: {
                  title: "Schedule",
                  hours: "10h per week",
                  days: "2 hours / day",
                },
                schedules: [
                  {
                    items: [
                      {
                        title: "Barcelona",
                        hours: "2:00 - 4:00 pm",
                        days: "Mon to Fri*",
                      },
                      {
                        hours: "4:30 - 6:30 pm",
                        days: "Mon to Fri*",
                        with_border: true,
                      },
                      {
                        title: "Madrid",
                        hours: "1:30 - 3:30 pm",
                        days: "Mon to Fri*",
                      },
                      {
                        hours: "3:30 - 5:30 pm",
                        days: "Mon to Fri*",
                      },
                    ],
                  },
                ],
                calendar: {
                  text: "See our calendar for start dates",
                  link: "/files/A3_CALENDAR_nlcollege_2024_both.pdf",
                },
                levels: {
                  title: "Levels",
                  desc: "From A1 to C2",
                },
                prices: {
                  title: "Price / month",
                  desc: ["€311"],
                  msg: "Remember: the more weeks you choose, the more discount you get!",
                },
                depending: "* depending on availability",
              },
              description: [
                {
                  subtitle:
                    "An effective Spanish course that fits your busy schedule",
                  body: `
              <p>Our Semi-Intensive Spanish Course is the perfect solution! Designed for students
              with limited time to invest in studying Spanish or those who want to combine it
              with work or other studies, our course offers a good dynamic group and
              affordable classes compared to individual lessons.</p>

              <p>Our expert teachers will guide you through a comprehensive curriculum that
              covers all aspects of the Spanish language, from oral expression to writing and
              comprehension. Join our friendly and professional learning environment today and
              take the first step towards mastering Spanish!</p>`,
                },
              ],
            },
            {
              title: "EXTENSIVE",
              visa_requirements: null,
              check_prices: "Check our price list",
              cta: "I WANT TO KNOW MORE",
              table: {
                schedule: {
                  title: "Schedule",
                  hours: "4h per week",
                  days: "2 hours / day",
                },
                schedules: [
                  {
                    items: [
                      {
                        title: "Barcelona",
                        hours: "6:30 - 8:30 pm",
                        days: "Mon and Wed*",
                      },
                      {
                        hours: "6:30 - 8:30 pm",
                        days: "Tue and Thurs*",
                        with_border: true,
                      },
                      {
                        title: "Madrid",
                        hours: "6:30 - 8:30 pm",
                        days: "Mon and Wed*",
                      },
                      {
                        hours: "6:30 - 8:30 pm",
                        days: "Tue and Thurs*",
                      },
                    ],
                  },
                ],
                calendar: {
                  text: "See our calendar for start dates",
                  link: "/files/A3_CALENDAR_nlcollege_2024_both.pdf",
                },
                levels: {
                  title: "Levels",
                  desc: "From A1 to C2",
                },
                prices: {
                  title: "Price / month",
                  desc: ["€140"],
                  msg: "Remember: the more weeks you choose, the more discount you get!",
                },
                depending: "* depending on availability",
              },
              description: [
                {
                  subtitle:
                    "Ideal for balancing your daily routine with your studies",
                  body: `
              <p>
                We understand that our students lead busy lives, which is why our extensive
                Spanish classes are designed to accommodate those with full-time jobs,
                university studies, or other responsibilities during the week. We aim to
                provide you with the flexibility you need to balance your daily routine with
                your studies.
              </p>

              <p>
                Our expert teachers will guide you through a comprehensive curriculum that
                covers all aspects of the Spanish language, from oral expression to writing
                and comprehension. Our friendly and professional learning environment will
                help you feel supported and inspired as you work towards your language
                learning goals.
              </p>

              <p>
                Invest in your future today by joining our extensive Spanish classes. We look
                forward to welcoming you to our community of passionate language learners!
              </p>
              `,
                },
              ],
            },
            {
              title: "CONVERSATION CLASS",
              visa_requirements: null,
              check_prices: "Check our price list",
              cta: "I WANT TO KNOW MORE",
              table: {
                schedule: {
                  title: "Schedule",
                  hours: "5h per week",
                  days: "1 hour / day",
                },
                schedules: [
                  {
                    items: [
                      {
                        title: "Barcelona",
                        hours: "Afternoon",
                        days: "Mon to Fri",
                        with_border: true,
                      },
                      {
                        title: "Madrid",
                        hours: "Afternoon",
                        days: "Mon to Fri",
                      },
                    ],
                  },
                ],
                calendar: {
                  text: "See our calendar for start dates",
                  link: "/files/A3_CALENDAR_nlcollege_2024_both.pdf",
                },
                levels: {
                  title: "Levels",
                  desc: "From A2 to C1",
                },
                prices: {
                  title: "Price / month",
                  desc: ["€136"],
                  msg: "Remember: the more weeks you choose, the more discount you get!",
                },
                depending: null,
              },
              description: [
                {
                  subtitle: "Take your Spanish to the next level",
                  body: `
                    <p>
                      Our Conversation classes are designed to enhance communicative skills and
                      confidence in expressing oneself in Spanish. Aimed at students at level A2 or
                      above, it focuses on oral expression practice, vocabulary, pronunciation, and
                      language proficiency.
                    </p>
                    <p>
                      Classes are varied and dynamic, organized into 5 weekly hours, with one hour
                      of conversation daily from Monday to Friday. The course covers five different
                      weekly categories to diversify content and encourage active student
                      participation. Authentic materials such as newspapers, songs, and current
                      audiovisual materials are used.
                    </p>
                    <p>
                      *Categories: Debates, Conversation based on a proposed topic, Oral mediation,
                      Dialogue and role-playing games, and Oral presentations.
                    </p>`,
                },
              ],
            },
            {
              title: "BUSINESS CLASS",
              visa_requirements: null,
              check_prices: "Check our price list",
              cta: "I WANT TO KNOW MORE",
              table: {
                schedule: {
                  title: "Schedule",
                  hours: "5h per week",
                  days: "1 hour / day",
                },
                schedules: [
                  {
                    items: [
                      {
                        title: "Barcelona",
                        hours: "Afternoon",
                        days: "Mon to Fri",
                        with_border: true,
                      },
                      {
                        title: "Madrid",
                        hours: "Afternoon",
                        days: "Mon to Fri",
                      },
                    ],
                  },
                ],
                calendar: {
                  text: "See our calendar for start dates",
                  link: "/files/A3_CALENDAR_nlcollege_2024_both.pdf",
                },
                levels: {
                  title: "Levels",
                  desc: "From A2 to C1",
                },
                prices: {
                  title: "Price / month",
                  desc: ["€175"],
                  msg: "Remember: the more weeks you choose, the more discount you get!",
                },
                depending: null,
              },
              description: [
                {
                  subtitle: "Speaking Spanish like a boss",
                  body: `
                    <p>
                      The Business Spanish classes at NL College are designed for those who need to
                      navigate the workplace and seek to prepare themselves for working in a global
                      context. These classes will help students enhance their CV and stand out among
                      other professionals.
                    </p>
                    <p>
                      Designed for students at level A2 or higher, every class focuses on practicing
                      Spanish in professional and business contexts. Classes are varied and
                      practical, organized into 5 weekly hours, one hour daily from Monday to
                      Friday.
                    </p>
                    <p>
                      The program includes activities such as reading comprehension of technical
                      texts, business and specialized conversations, business vocabulary, and
                      communication in specific sectors such as medicine, law, international
                      relations, tourism, and diplomacy. Additionally, it is tailored to the profile
                      and expectations of the students to offer personalized training.
                    </p>
`,
                },
              ],
            },
          ],
        },
        {
          anchor: "es-packages",
          title: "Packages",
          items: [
            {
              title: "ACELERADO PLUS 25h",
              visa_requirements: null,
              check_prices: "Check our price list",
              cta: "I WANT TO KNOW MORE",
              table: {
                schedule: {
                  title: "Schedule",
                  hours: "25h per week",
                  days: "4h + 1h / day",
                },
                schedules: [
                  {
                    items: [
                      {
                        title: "Barcelona",
                        hours: "Accelerated",
                        bold: true,
                      },
                      {
                        hours: "9:30 - 1:30 pm",
                        days: "Mon to Fri",
                      },
                      {
                        hours: "Conversation",
                        bold: true,
                      },
                      {
                        hours: "2:00 - 3:00 pm or",
                      },
                      {
                        hours: "4:00 - 5:00 pm",
                        days: "Mon to Fri",
                      },
                      {
                        hours: "Business",
                        bold: true,
                      },
                      {
                        hours: "3:00 - 4:00 pm or",
                      },
                      {
                        hours: "4:00 - 5:00 pm",
                        days: "Mon to Fri",
                        with_border: true,
                      },
                      {
                        title: "Madrid",
                        hours: "Accelerated",
                        bold: true,
                      },
                      {
                        hours: "9:00 - 1:00 pm",
                        days: "Mon to Fri",
                      },
                      {
                        hours: "Conversation",
                        bold: true,
                      },
                      {
                        hours: "1:30 - 2:30 pm or",
                      },
                      {
                        hours: "3:30 - 4:30 pm",
                        days: "Mon to Fri",
                      },
                      {
                        hours: "Business",
                        bold: true,
                      },
                      {
                        hours: "2:30 - 3:30 pm or",
                      },
                      {
                        hours: "4:30 - 5:30 pm",
                        days: "Mon to Fri",
                        with_border: true,
                      },
                    ],
                  },
                ],
                calendar: {
                  text: "See our calendar for start dates",
                  link: "/files/A3_CALENDAR_nlcollege_2024_both.pdf",
                },
                levels: {
                  title: "Levels",
                  desc: "From A2 to C2",
                },
                prices: {
                  title: "Price 6 months",
                  desc: [
                    "Accelerated + Converssation: € 4,083",
                    "Accelerated + Business: € 4,287",
                  ],
                  msg: "Remember: the more weeks you choose, the more discount you get!",
                },
                depending: "* depending on availability",
              },
              description: [
                {
                  subtitle: "Make the most of your stay in Spain",
                  body: `
                    <p class='h6'>2 options:</p>

                    <ul class="h6 disc-style disc-start disc-tertiary">
                      <li>Accelerated (20 h) + Conversation (5 h)</li>
                      <li>Accelerated (20 h) + Business (5 h)</li>
                    </ul>


                    <p>
                      At NL College, we offer the Accelerated Plus Spanish course, designed to
                      maximize learning in a short period of time. This course combines 20 hours of
                      general Spanish classes per week with an additional 5 hours of your choice
                      between conversation lessons or business Spanish, totaling 25 hours per week.
                    </p>
                    <p>
                      The classes focus on developing all language skills, including comprehension,
                      expression, interaction, and mediation, using a communicative and
                      student-centered approach. This course is ideal for those who want to make the
                      most of their stay in Spain, progressing from level to level while improving
                      their oral expression and investing in their professional future.
                    </p>
                    <p>
                      The Accelerated Plus program is an excellent option for students seeking an
                      immersive and efficient learning experience, whether for a week or a month.
                      Group classes are held in the mornings, with additional immersion activities
                      to make the most of their stay in Barcelona or Madrid. Additionally, students
                      receive learning and communication strategies to reflect on their learning
                      process and address the cultural diversity of the Hispanic world.
                    </p>
`,
                },
              ],
            },
            {
              title: "ACELERADO Premium 30h",
              visa_requirements: null,
              check_prices: "Check our price list",
              cta: "I WANT TO KNOW MORE",
              table: {
                schedule: {
                  title: "Schedule",
                  hours: "30h per week",
                  days: "4h + 1h + 1h / day",
                },
                schedules: [
                  {
                    items: [
                      {
                        title: "Barcelona",
                        hours: "Accelerated",
                        bold: true,
                      },
                      {
                        hours: "9:30 - 1:30 pm",
                        days: "Mon to Fri",
                      },
                      {
                        hours: "Conversation & Business",
                        bold: true,
                      },
                      {
                        hours: "2:00 - 3:00 pm or",
                      },
                      {
                        hours: "4:00 - 5:00 pm",
                        days: "Mon to Fri",
                        with_border: true,
                      },
                      {
                        title: "Madrid",
                        hours: "Accelerated",
                        bold: true,
                      },
                      {
                        hours: "9:00 - 1:00 pm",
                        days: "Mon to Fri",
                      },
                      {
                        hours: "Conversation & Business",
                        bold: true,
                      },
                      {
                        hours: "1:30 - 2:30 pm or",
                      },
                      {
                        hours: "3:30 - 4:30 pm",
                        days: "Mon to Fri",
                      },
                    ],
                  },
                ],
                calendar: {
                  text: "See our calendar for start dates",
                  link: "/files/A3_CALENDAR_nlcollege_2024_both.pdf",
                },
                levels: {
                  title: "Levels",
                  desc: "From A2 to C2",
                },
                prices: {
                  title: "Price 6 months",
                  desc: ["€ 5,001"],
                  msg: "Remember: the more weeks you choose, the more discount you get!",
                },
                depending: "* depending on availability",
              },
              description: [
                {
                  subtitle: "The most complete Spanish course",
                  body: `
                    <p class='h6'>2 options:</p>

                    <ul class="h6 disc-style disc-start disc-tertiary">
                      <li>Accelerated (4 h) <br> + Conversation (1 h) <br> + Business (1 h)</li>
                    </ul>

                    <p>
                      At our school, we focus on helping our students achieve their learning goals
                      by adapting to their profiles, needs, and schedules. We offer the Accelerated
                      Plus Premium Spanish course, which combines general Spanish classes (4 hours
                      daily) with 1 daily conversation lesson and 1 business Spanish lesson,
                      totaling 30 hours per week. This intensive course of 6 hours per day is ideal
                      for those looking to maximize their time and enjoy a comprehensive learning
                      experience in Spain.
                    </p>
                    <p>
                      The Accelerated Plus Premium course not only covers all aspects of the
                      language but also improves oral expression and provides valuable tools for the
                      students' professional futures. Designed with a communicative and
                      student-centered approach, this course is an excellent option for those who
                      want to fully invest in their Spanish learning. NL College is committed to
                      offering a modern and meaningful view of the Spanish language and culture,
                      ensuring active and reflective learning.
                    </p>`,
                },
              ],
            },
          ],
        },
        {
          anchor: "es-private-classes",
          title: "Private Classes",
          items: [
            {
              title: "PRIVATE CLASSES",
              visa_requirements: null,
              check_prices: "Check our price list",
              cta: "I WANT TO KNOW MORE",
              table: {
                schedule: {
                  title: "Schedule",
                  hours: "Max. of 9,10h / week",
                  days: "1:30h each class",
                },
                schedules: [
                  {
                    items: [
                      {
                        hours: "From 8:30h",
                        days: "Mon to Fri",
                      },
                      {
                        hours: "to 20:30h",
                      },
                    ],
                  },
                ],
                calendar: null,
                levels: {
                  title: "Levels",
                  desc: "From A2 to C2",
                },
                prices: null,
                depending: "* depending on availability",
              },
              description: [
                {
                  subtitle: "Flexible and personalized Spanish classes",
                  body: `
                    <p>
                      We offer private Spanish classes that provide flexibility and personalized
                      support for students to achieve their learning goals. These classes are fully
                      tailored to each student's learning style and specific objectives, making them
                      ideal for those seeking flexibility and adaptability. Our exclusive "Natural
                      Learning and Communicative" program allows students to progress through
                      different courses and levels, facilitating the achievement of their personal
                      learning goals.
                    </p>
                    <p>
                      Additionally, we offer private classes for specific purposes such as business
                      Spanish, medical Spanish, international relations, or diplomacy. We also have
                      private classes for children and teenagers, with engaging activities and
                      specialized materials that promote meaningful learning. These classes can be
                      individual, for couples, friends, or small groups of up to three people, with
                      schedules fully adapted to their availability. With highly qualified teachers,
                      our students will develop their Spanish at their own pace and according to
                      their needs.
                    </p>`,
                },
              ],
            },
          ],
        },
        {
          anchor: "es-online-classes",
          title: "Online Classes",
          items: [
            {
              title: "ACCELERATED - ONLINE",
              visa_requirements: null,
              check_prices: "Check our price list",
              cta: "I WANT TO KNOW MORE",
              table: {
                schedule: {
                  title: "Schedule",
                  hours: "20h per week",
                  days: "4 hours / day",
                },
                schedules: [
                  {
                    items: [
                      {
                        title: "Barcelona",
                        hours: "9:30 - 1:30 pm",
                        days: "Mon to Fri*",
                      },
                      {
                        hours: "2:00 - 6:00 pm",
                        days: "Mon to Fri*",
                        with_border: true,
                      },
                      {
                        title: "Madrid",
                        hours: "9:00 - 1:00 pm",
                        days: "Mon to Fri*",
                      },
                      {
                        hours: "1:30 - 5:30 pm",
                        days: "Mon to Fri*",
                      },
                    ],
                  },
                ],
                calendar: {
                  text: "See our calendar for start dates",
                  link: "/files/A3_CALENDAR_nlcollege_2024_both.pdf",
                },
                levels: {
                  title: "Levels",
                  desc: "From A2 to C2",
                },
                prices: {
                  title: "Price / month",
                  desc: ["€ 634"],
                  msg: "Remember: the more weeks you choose, the more discount you get!",
                },
                depending: "* depending on availability",
              },
              description: [
                {
                  subtitle: "Learn Spanish fast from the comfort of your home!",
                  body: `
                    <p>
                      The online Accelerated Spanish Course is the best option for students who want
                      to learn Spanish as quickly as possible from the comfort of their home or from
                      anywhere, making the most of their time. Reach your Spanish learning goal with
                      our exclusive and unique learning program, and contribute to reducing your
                      carbon footprint by avoiding commutes with NL College!
                    </p>
                    <p>
                      With our online Accelerated Course, you will see immediate results and develop
                      your communication skills in a short time, just like in our face-to-face
                      courses. You will be able to see, listen, interact, and speak with your
                      teacher and classmates as in a class on our Campus.
                    </p>
                    <p>
                      At NL College, we use the Zoom platform which provides us with all the
                      resources available for the best learning experience: a virtual whiteboard,
                      the ability to share documents, work in pairs or small groups, and combine
                      conversation through chat and microphone. Of course, you will also have access
                      to the class manual in an online version along with all the audiovisual
                      materials on our digital Campus Difusión platform.
                    </p>
                    <p>
                      Our teachers will guide you through daily exercises of oral expression or
                      written comprehension, ensuring that your exposure to the language is constant
                      and of quality
                    </p>`,
                },
              ],
            },
            {
              title: "SEMI-INTENSIVE - ONLINE",
              visa_requirements: null,
              check_prices: "Check our price list",
              cta: "I WANT TO KNOW MORE",
              table: {
                schedule: {
                  title: "Schedule",
                  hours: "10h per week",
                  days: "2 hours / day",
                },
                schedules: [
                  {
                    items: [
                      {
                        title: "Barcelona",
                        hours: "2:00 - 4:00 pm",
                        days: "Mon to Fri*",
                      },
                      {
                        hours: "4:00 - 6:00 pm",
                        days: "Mon to Fri*",
                        with_border: true,
                      },
                      {
                        title: "Madrid",
                        hours: "1:30 - 3:30 pm",
                        days: "Mon to Fri*",
                      },
                      {
                        hours: "3:30 - 5:30 pm",
                        days: "Mon to Fri*",
                      },
                    ],
                  },
                ],
                calendar: {
                  text: "See our calendar for start dates",
                  link: "/files/A3_CALENDAR_nlcollege_2024_both.pdf",
                },
                levels: {
                  title: "Levels",
                  desc: "From A1 to C2",
                },
                prices: {
                  title: "Price / month",
                  desc: ["Afternoon - €311"],
                  msg: "Remember: the more weeks you choose, the more discount you get!",
                },
                depending: "* depending on availability",
              },
              description: [
                {
                  subtitle:
                    "An effective Spanish course that fits your busy schedule",
                  body: `
                    <p>
                      The Semi-Intensive Remote Spanish Course is the best option for students with
                      limited time to invest in studying Spanish or those who want to combine it
                      with work, family, or studies without having to spend time commuting.
                    </p>
                    <p>
                      With our online Semi-Intensive Course, you will see immediate results and
                      develop your communication skills with just two hours of class per day, just
                      like in our face-to-face courses. You will be able to see, listen, interact,
                      and speak with your teacher and classmates as in a class on our Campus.
                    </p>
                    <p>
                      At NL College, we use the Zoom platform which provides us with all the
                      resources available for the best learning experience: a virtual whiteboard,
                      the ability to share documents, work in pairs or small groups, and combine
                      conversation through chat and microphone. Of course, you will also have access
                      to the class manual in an online version along with all the audiovisual
                      materials on our Campus Difusión platform.
                    </p>
                    <p>
                      Our teachers will guide you through daily exercises of oral expression or
                      written comprehension, ensuring that your exposure to the language is constant
                      and of quality.
                    </p>`,
                },
              ],
            },
            {
              title: "PRIVATE CLASSES - ONLINE",
              visa_requirements: null,
              check_prices: "Check our price list",
              cta: "I WANT TO KNOW MORE",
              table: {
                schedule: {
                  title: "Schedule",
                  hours: "Max. of 9,10h / week",
                  days: "1:30h each class",
                },
                schedules: [
                  {
                    items: [
                      {
                        hours: "From 8:30h",
                        days: "Mon to Fri",
                      },
                      {
                        hours: "to 20:30h",
                      },
                    ],
                  },
                ],
                calendar: null,
                levels: {
                  title: "Levels",
                  desc: "From A2 to C2",
                },
                prices: null,
                depending: "* depending on availability",
              },
              description: [
                {
                  subtitle: "Flexible and personalized Spanish classes",
                  body: `
                    <p>
                      Experience flexible and personalized Spanish language instruction through our
                      online private classes. With our private online classes, students will enjoy a
                      fully customized course tailored to their interests, objectives, and specific
                      Spanish language needs, from the comfort of their own homes, or from anywhere
                      in the world
                    </p>
                    <p>
                      This course adapts our exclusive learning program to the format of private
                      lessons, completing the program in less time and in a completely personalized
                      and flexible manner.
                    </p>
                    <p>
                      With our private classes options, students will receive the individual and
                      fully tailored support they need to develop their Spanish language skills as
                      quickly as possible. Our express Spanish courses are completely flexible and
                      are designed to adapt to our students’ schedule and needs.
                    </p>`,
                },
              ],
            },
          ],
        },
      ],
    },
    english_courses: {
      header: '<span class="text-secondary">English</span> <br /> courses',
      information: {
        header: "Hey! Let’s go!",
        paragraph1:
          "English is the universal language and a crucial skill for those seeking to advance professionally and connect with people from around the world.",
        paragraph2:
          "At NL College, we offer dynamic English classes with native teachers in three different formats to fit your schedule and goals: semi-intensive, extensive, and private. Don't miss this opportunity to enhance your language skills and broaden your horizons - enroll today!",
        paragraph3:
          "Please note that our English courses do not meet the requirements for obtaining a student visa in Spain.",
      },
      buttons: [
        {
          anchor: "#en-extensive",
          title: "Extensive",
        },
        {
          anchor: "#en-private-classes",
          title: "Private Classes",
        },
      ],
      items: [
        {
          anchor: "en-extensive",
          title: "EXTENSIVE",
          visa_requirements: null,
          check_prices: "Check our price list",
          cta: "I WANT TO KNOW MORE",
          table: {
            schedule: {
              title: "Schedule",
              hours: "4h per week",
              days: "2 hours / day",
            },
            schedules: [
              {
                items: [
                  {
                    hours: "6:30 - 8:30 pm",
                    days: "Mon and Wed*",
                  },
                  {
                    hours: "",
                    days: "Tue and Thurs*",
                  },
                ],
              },
            ],
            calendar: {
              text: "See our calendar for start dates",
              link: "/files/A3_CALENDAR_nlcollege_2024_both.pdf",
            },
            levels: {
              title: "Levels",
              desc: "From A1 to C1",
            },
            prices: {
              title: "Price / week",
              desc: ["€ 36"],
              msg: "Remember: the more weeks you choose, the more discount you get!",
            },
            depending: "* depending on availability",
          },
          description: [
            {
              subtitle:
                "Ideal for balancing your daily routine with your studies",
              body: `
              <p>We understand that our students lead busy lives, which is why our extensive
              English classes are designed to accommodate those with full-time jobs,
              university studies, or other responsibilities during the week. We aim to provide
              you with the flexibility you need to balance your daily routine with your
              studies.</p>

              <p>Our expert teachers will guide you through a comprehensive curriculum that
              covers all aspects of the English language, from oral expression to writing and
              comprehension. Our friendly and professional learning environment will help you
              feel supported and inspired as you work towards your language learning
              goals.</p>

              <p>Invest in your future today by joining our extensive English classes. We look
              forward to welcoming you to our community of passionate language learners!</p>
              `,
            },
          ],
        },
        {
          anchor: "en-private-classes",
          title: "PRIVATE CLASSES",
          visa_requirements: null,
          check_prices: "Check our price list",
          cta: "I WANT TO KNOW MORE",
          table: null,
          description: [
            {
              subtitle: "Flexible and personalized English classes",
              body: `
              <p>Our English language courses are tailored to your needs and schedule, with
              our teachers adapting to your learning style and goals. Whether you prefer
              morning or afternoon classes, we can accommodate your availability any day of
              the week.</p>

              <p>Our classes are available for individuals or small groups of up to 3 people,
              taught by experienced native teachers who specialize in teaching English to
              foreigners. You can choose to attend classes in person at our school or have our
              teachers come to your home or workplace.</p>

              <p>For added convenience, we also offer virtual private classes via Zoom at the
              same price as our face-to-face sessions.</p>
              `,
            },
          ],
        },
      ],
    },
    dele_courses: {
      header:
        '<span class="text-primary">DELE, CCSE & SIELE</span> <br /> preparation courses',
      information: {
        header: "Proof what you’ve got",
        paragraph1:
          "Getting your DELE (Diploma de Español como Lengua Extranjera) and CCSE (Conocimientos Constitucionales y Socioculturales de España) certifications isn't just important; it's a game-changer for anyone looking to live and develop their personal and professional career in Spain.",
        paragraph2:
          "Whether you're aiming for career growth, university admissions, obtaining your Spanish nationality or simply immersing yourself in Spanish life, these certifications are your golden ticket. Let's explore why they're so crucial for success in work, university, and everyday life in Spain.",
      },
      buttons: [
        {
          anchor: "#dele",
          title: "DELE (A2 & B2)",
        },
        {
          anchor: "#ccse",
          title: "CCSE",
        },
        {
          anchor: "#siele",
          title: "SIELE",
        },
      ],
      items: [
        {
          anchor: "dele",
          title: "DELE PREPARATION",
          visa_requirements: null,
          check_prices: "Check our price list",
          cta: "I WANT TO KNOW MORE",
          table: {
            schedule: {
              title: "Schedule <br> (4 weeks)",
              hours: "4h per week",
              days: "2 hours / day",
            },
            schedules: [
              {
                title: "A2 Preparation course",
                items: [
                  {
                    title: "Barcelona",
                    hours: "2:00 - 3:50 pm",
                    days: "Mon to Fri*",
                    with_border: true,
                  },
                  {
                    title: "Madrid",
                    hours: "1:30 - 3:20 pm",
                    days: "Mon to Fri*",
                  },
                ],
              },

              {
                title: "B2 Preparation course",
                items: [
                  {
                    title: "Barcelona",
                    hours: "9:30 - 11:20 pm",
                    days: "Mon to Fri*",
                  },
                  {
                    hours: "2:00 - 3:50 pm",
                    days: "Mon to Fri*",
                    with_border: true,
                  },
                  {
                    title: "Madrid",
                    hours: "9:00 - 10:50 pm",
                    days: "Mon to Fri*",
                  },
                  {
                    hours: "1:30 - 3:20 pm",
                    days: "Mon to Fri*",
                  },
                ],
              },
            ],
            calendar: {
              text: "See our calendar for start dates and DELE exam calls",
              link: "/files/A3_CALENDAR_nlcollege_2024_both.pdf",
            },
            levels: {
              title: "Levels",
              desc: "DELE A2 or B2 level",
            },
            prices: {
              title: "Price / week",
              desc: ["€ 695 (4 weeks)"],
            },
            depending: "* depending on availability",
          },
          description: [
            {
              subtitle: "At NL College, we get you prepared!",
              body: `
              <p>
                If you're looking to take the official Spanish exam offered by the Instituto
                Cervantes, we have just the course for you. Our DELE preparation course is
                designed to help you ace your exam and achieve your goals. To join the course,
                you should have completed the A2 or B2 course at NL College or have an A2 or
                B2 level of Spanish already.
              </p>
              <p>
                We know how important it is to be well-prepared for the DELE exam, which is
                why our course is specifically designed to help you succeed. Instead of
                focusing on general grammar or conversation skills, we'll provide you with
                explanations and instructions on how to approach the exam so you can feel
                confident and prepared.
              </p>
              <p>
                Throughout the course, we'll be there to support you, address your doubts, and
                help you correct any errors you make. We'll also offer you a range of
                resources and guidance to help you complete the exam on time and develop your
                skills in each of its sections. We understand that passing the DELE exam
                requires a lot of dedication and hard work, but we're confident that with our
                help, you'll be able to achieve your goals.
              </p>

              <h6 class="text-primary mt-5 mb-4">DELE A2 schedule</h6>

              <div class="row exam-table-rows">
                <div class="col-md-4">
                  <p class="fw-600 mb-0">Preparation Course</p>
                  <p class="mb-4"><i>March 18th</i></p>
                  <p class="fw-600 mb-0">Registration</p>
                  <p class="mb-4"><i>Until April 3rd</i></p>
                  <p class="fw-600 mb-0">DELE Exam</p>
                  <p class="mb-4"><i>May 18th</i></p>
                </div>
                <div class="col-md-4">
                  <p class="fw-600 mb-0">Preparation Course</p>
                  <p class="mb-4"><i> May 21st </i></p>
                  <p class="fw-600 mb-0">Registration</p>
                  <p class="mb-4"><i> Until May 15th </i></p>
                  <p class="fw-600 mb-0">DELE Exam</p>
                  <p class="mb-4"><i> July 20th </i></p>
                </div>
                <div class="col-md-4">
                  <p class="fw-600 mb-0">Preparation Course</p>
                  <p class="mb-4"><i> September 30th </i></p>
                  <p class="fw-600 mb-0">Registration</p>
                  <p class="mb-4"><i> Until October 9th </i></p>
                  <p class="fw-600 mb-0">DELE Exam</p>
                  <p class="mb-4"><i> Nov. 22nd and 23rd </i></p>
                </div>
              </div>

              <h6 class="text-primary mt-5 mb-4">DELE B2 schedule</h6>

              <div class="row exam-table-rows">
                <div class="col-md-4">
                  <p class="fw-600 mb-0">Preparation Course</p>
                  <p class="mb-4"><i> April 22nd </i></p>
                  <p class="fw-600 mb-0">Registration</p>
                  <p class="mb-4"><i> Until February 21st </i></p>
                  <p class="fw-600 mb-0">DELE Exam</p>
                  <p class="mb-4"><i> May 17th and 18th </i></p>
                </div>
                <div class="col-md-4">
                  <p class="fw-600 mb-0">Preparation Course</p>
                  <p class="mb-4"><i> June 17th </i></p>
                  <p class="fw-600 mb-0">Registration</p>
                  <p class="mb-4"><i> Until May 15th </i></p>
                  <p class="fw-600 mb-0">DELE Exam</p>
                  <p class="mb-4"><i> July 12th and 13th </i></p>
                </div>
                <div class="col-md-4">
                  <p class="fw-600 mb-0">Preparation Course</p>
                  <p class="mb-4"><i> October 28th </i></p>
                  <p class="fw-600 mb-0">Registration</p>
                  <p class="mb-4"><i> Until October 9th </i></p>
                  <p class="fw-600 mb-0">DELE Exam</p>
                  <p class="mb-4"><i> Nov. 22nd and 23rd </i></p>
                </div>
              </div>
              `,
            },
          ],
        },
        {
          anchor: "ccse",
          title: "CCSE PREPARATION",
          visa_requirements: null,
          check_prices: "Check our price list",
          cta: "I WANT TO KNOW MORE",
          table: {
            schedule: {
              title: "Schedule <br> (4 weeks)",
              hours: "5h per week",
              days: "2.30 hours / day",
            },
            schedules: [
              {
                items: [
                  {
                    hours: "6:00 - 8:30 pm",
                    days: "Mon and Wed",
                  },
                ],
              },
            ],
            calendar: {
              text: "See our calendar for start dates and DELE exam calls",
              link: "/files/A3_CALENDAR_nlcollege_2024_both.pdf",
            },
            levels: {
              title: "Levels",
              desc: "From A2 to C1",
            },
            prices: {
              title: "Price / week",
              desc: ["€ 250 (4 weeks)"],
            },
            depending: "* depending on availability",
          },
          description: [
            {
              subtitle: "One step closer to obtaining your Spanish nationality",
              body: `
              <p>
                NL College offers eight CCSE preparation courses per year, with exams
                available in Madrid and Barcelona on the last Thursday of each month, except
                in August and December. The CCSE is an exam by the Instituto Cervantes that
                assesses knowledge of the Constitution and the cultural and social realities
                of Spain, a requirement for acquiring Spanish nationality.
              </p>
              <p>
                The course, aimed at reviewing CCSE content, requires completion of the A2
                course at NL College or an equivalent level of Spanish. The preparation is
                organized into two sections: Government, legislation, and citizen
                participation in Spain, and Spanish culture, history, and society.
              </p>
              <p>
                With a duration of 4 weeks and a total of 20 hours, the course allows flexible
                enrollments from one week up to four weeks before the exam. The main points of
                the syllabus are reviewed and test models are practiced, also requiring
                individual preparation at home to resolve doubts and take the exam with the
                content fresh in mind.
              </p>


              <h6 class="text-primary mt-5 mb-4">CCSE schedule</h6>

              <div class="row exam-table-rows">
                <div class="col-md-4">
                  <p class="fw-600 mb-0">Preparation Course</p>
                  <p class="mb-4"><i> February 26th </i></p>

                  <p class="fw-600 mb-0">CCSE Exam</p>
                  <p class="mb-4"><i> March 21st </i></p>

                  <p class="fw-600 mb-0">Preparation Course</p>
                  <p class="mb-4"><i> June 3rd </i></p>

                  <p class="fw-600 mb-0">CCSE Exam</p>
                  <p class="mb-4"><i> June 27th </i></p>

                  <p class="fw-600 mb-0">Preparation Course</p>
                  <p class="mb-4"><i> October 7th </i></p>

                  <p class="fw-600 mb-0">CCSE Exam</p>
                  <p class="mb-4"><i> October 31st </i></p>
                </div>
                <div class="col-md-4">
                  <p class="fw-600 mb-0">Preparation Course</p>
                  <p class="mb-4"><i> April 1st </i></p>

                  <p class="fw-600 mb-0">CCSE Exam</p>
                  <p class="mb-4"><i> April 25th </i></p>

                  <p class="fw-600 mb-0">Preparation Course</p>
                  <p class="mb-4"><i> July 1st </i></p>

                  <p class="fw-600 mb-0">CCSE Exam</p>
                  <p class="mb-4"><i> July 25th </i></p>

                  <p class="fw-600 mb-0">Preparation Course</p>
                  <p class="mb-4"><i> November 4th </i></p>

                  <p class="fw-600 mb-0">CCSE Exam</p>
                  <p class="mb-4"><i> November 28th </i></p>
                </div>
                <div class="col-md-4">
                  <p class="fw-600 mb-0">Preparation Course</p>
                  <p class="mb-4"><i> May 6th </i></p>

                  <p class="fw-600 mb-0">CCSE Exam</p>
                  <p class="mb-4"><i> May 30th </i></p>

                  <p class="fw-600 mb-0">Preparation Course</p>
                  <p class="mb-4"><i> September 2nd </i></p>

                  <p class="fw-600 mb-0">CCSE Exam</p>
                  <p class="mb-4"><i> September 26th </i></p>
                </div>
              </div>

              `,
            },
          ],
        },
        {
          anchor: "siele",
          title: "SIELE PREPARATION - ONLINE",
          visa_requirements: null,
          check_prices: "Check our price list",
          cta: "I WANT TO KNOW MORE",
          table: {
            schedule: {
              title: "Schedule <br> (4 weeks)",
              hours: "35 - 40h",
              days: "Flexible: choose the days and hours you want to study",
            },
            schedules: [],
            calendar: null,
            levels: {
              title: "Levels",
              desc: "From A2",
            },
            prices: {
              title: "Price",
              desc: ["€ 199"],
            },
            depending: "* depending on availability",
          },
          description: [
            {
              subtitle: "Accredit your Spanish proficiency",
              body: `<p>
                The SIELE Global preparation course is designed for those interested in
                obtaining an official certificate that accredits their proficiency in Spanish.
                This course is included for students taking the exam on the institution's
                campus.
              </p>
              <p>
                SIELE (International Spanish Language Evaluation Service) is an evaluation and
                certification system for Spanish proficiency for global students and
                professionals, based on the Common European Framework of Reference for
                Languages (CEFR). Native Language College, with campuses in Barcelona and
                Madrid, is an official center for taking these online exams, offering
                flexibility in choosing the date and time.
              </p>
              <p>
                The SIELE certification is valuable for accessing prestigious universities,
                applying for scholarships, or improving job opportunities, and is endorsed by
                institutions such as the Instituto Cervantes and the University of Salamanca.
                This certificate significantly enriches the resume, comparable to other
                recognized language certificates like the First or TOEFL. Upon completing the
                course, students will be well-prepared to take the tests, understand their
                structure and content, and apply effective strategies to optimize their
                results.
              </p>`,
            },
          ],
        },
      ],
    },
    footer: {
      title: "We just started!",
      card1: {
        title: "Meet your teachers",
        body: "Know a little bit more about our team",
      },
      card2: {
        title: "How many levels are there?",
        body: "Whether you're a beginner or an advanced learner, there's a course for you",
      },
      card3: {
        title: "Who said learning couldn't be fun?",
        body: "We have different extra cultural activities every month. Check them out!",
      },
    },
    contact_form: {
      location: "Aerial view from La Sagrada Família, Barcelona",
    },
  },
  our_packages: {
    title: "Our Packages",
    cta: "I WANT TO KNOW MORE",
    section1: {
      header: 'It\'s time to <span class="text-tertiary">pack!</span>',
      paragraph1:
        "Whether you're a student beginning or completing your university degree in Spain, a group seeking a blend of learning and holiday experiences, or someone eager to explore the seasonal wonders of winter or summer, we have a package for you. Our focus is on improving our students' experience across all areas of life, be it their career path, education, work, or leisure.",
    },
    group_exp: {
      header: `Barcelona Group <span class="text-tertiary">Language Experience</span>`,
      title:
        "Package Options for Study & Travel in Spain. Plan your next holiday with us!",
      choose: "Choose between",
      buttons: [
        {
          anchor: "#package1",
          title: "Package 1: Discover & Dive In",
        },
        {
          anchor: "#package2",
          title: "Package 2: Explore & Immerse",
        },
        {
          anchor: "#package3",
          title: "Package 3: Language Retreat",
        },
      ],
      tables: [
        {
          id: "package1",
          title: "Package 1: Discover & Dive In",
          cost: "Total Cost: € 490/person",
          items: [
            {
              title: "Accelerated Spanish Course",
              description:
                "5 days - 4 hours/day (9:00 - 13:00 or 14:00 - 18:00) <br> Registration, materials and books are included",
            },
            {
              title: "Accommodation (6 nights)",
              description: "Shared dormitory with breakfast included",
            },
            {
              title: "Activities (5 Days)*",
              description: `<ul>
                <li>Cultural city tour</li>
                <li>Beach day</li>
                <li>Local tapas evening</li>
              </ul>
              <ul>
                <li>Montjuïc mountain</li>
                <li>Ciutadella park</li>
              </ul>`,
            },
            {
              title: "Airport Transfer",
              description: "Included, by public bus",
            },
            {
              title: "Public travel card",
              description: "With 10 journeys by metro/bus",
            },
            {
              description:
                "Duration: 7 days, 6 nights. Starting Day: on demand. Groups: minimum 10 students <br> * Depending on availability, activities may change",
            },
          ],
        },
        {
          id: "package2",
          title: "Package 2: Explore & Immerse",
          cost: "Total Cost: € 530/person",
          items: [
            {
              title: "Accelerated Spanish Course",
              description:
                "5 days - 4 hours/day (14:00 - 18:00) <br> Registration, materials and books are included",
            },
            {
              title: "Accommodation (6 nights)",
              description: "Shared dormitory with breakfast included",
            },
            {
              title: "Activities (5 Days)*",
              description: `<ul>  
                <li>Guided excursion to nearby attractions - 2 surprise activities</li>
                <li>Parc Güell</li>
                <li>Paella cooking class</li>
              </ul>
              <ul>  
                <li>Evening flamenco show</li>
                <li>Jardí Botànic de Barcelona</li>
                <li>Spotify Camp Nou Tour and Museum</li>
              </ul>`,
            },
            {
              title: "Airport Transfer",
              description: "Included, by public bus",
            },
            {
              title: "Public travel card",
              description: "With 10 journeys by metro/bus",
            },
            {
              description:
                "Duration: 7 days, 6 nights. Starting Day: on demand. Groups: minimum 10 students <br> * Depending on availability, activities may change",
            },
          ],
        },
        {
          id: "package3",
          title: "Package 3: Language Retreat",
          cost: "Total Cost: € 600/person",
          items: [
            {
              title: "Accelerated Spanish Course",
              description:
                "5 days - 4 hours/day (14:00 - 18:00) <br>Registration, materials and books are included",
            },
            {
              title: "Accommodation (6 nights)",
              description: "Shared dormitory with breakfast included",
            },
            {
              title: "Activities (5 Days)*",
              description: `<ul>
                <li>PortAventura</li>
                <li>Chocolate Museum</li>
                <li>Design Museum from Barcelona</li>
              </ul>
              <ul>
                <li>Gourmet dining experience</li>
                <li>Beach day</li>
                <li>Sagrada Familia: Entrance with Audio Guide</li>
              </ul>
`,
            },
            {
              title: "Airport Transfer",
              description: "Included, by public bus",
            },
            {
              title: "Public travel card",
              description: "With 10 journeys by metro/bus",
            },
            {
              description:
                "Duration: 7 days, 6 nights. Starting Day: on demand. Groups: minimum 10 students <br> * Depending on availability, activities may change",
            },
          ],
        },
      ],
    },
    season_exp: {
      header:
        "Barcelona Summer and winter <span class='text-tertiary'>Language Experience</span>",
      title:
        "Package Options for Study & Travel in Spain during Summer and winter",
      subtitle:
        "Let's create unforgettable experiences together while you are learning a new language",
      choose: "Choose between",
      buttons: [
        {
          anchor: "#summer_packages",
          title: "Summer packages",
        },
        {
          anchor: "#winter_packages",
          title: "Winter packages",
        },
      ],
      groups: [
        {
          id: "summer_packages",
          title: "SUMMER PACKAGES",
          tables: [
            {
              title: "Package 1: Adventure Mood",
              cost: "Total Cost: € 330/person",
              items: [
                {
                  title: "Accelerated Spanish Course",
                  description:
                    "5 days - 4 hours/day (9:00 - 13:00 or 14:00 - 18:00) <br>Registration, materials and books are included",
                },
                {
                  title: "Activities (5 Days)*",
                  description: `<ul>  
                    <li>Park Güell</li>
                    <li>Paddle Surf</li>
                    <li>Costa Brava hiking</li>
                  </ul>
                  <ul>  
                    <li>Walking tours</li>
                    <li>Montjuïc mountain</li>
                  </ul>`,
                },
                {
                  title: "Airport Transfer",
                  description: "Included, by public bus",
                },
                {
                  title: "Public travel card",
                  description: "With 10 journeys by metro/bus",
                },
                {
                  description:
                    "Duration: 5 days from Monday to Friday during July, August or September. Starting Day: you can join every Monday <br>* Depending on availability, activities may change",
                },
              ],
            },
            {
              title: "Package 2: Chilling Mood",
              cost: "Total Cost: € 370/person",
              items: [
                {
                  title: "Accelerated Spanish Course",
                  description:
                    "5 days - 4 hours/day (9:00 - 13:00 or 14:00 - 18:00)<br>Registration, materials and books are included",
                },
                {
                  title: "Activities (5 Days)*",
                  description: `<ul>  
                    <li>Jardí Botànic de Barcelona</li>
                    <li>Visit to Museo Picasso</li>
                    <li>Visit to la Pedrera</li>
                  </ul>
                  <ul>  
                    <li>Visit to Casa Vicens</li>
                    <li>Yoga class</li>
                  </ul>`,
                },
                {
                  title: "Airport Transfer",
                  description: "Included, by public bus",
                },
                {
                  title: "Public travel card",
                  description: "With 10 journeys by metro/bus",
                },
                {
                  description:
                    "Duration: 5 days from Monday to Friday during July, August or September. Starting Day: you can join every Monday<br>* Depending on availability, activities may change",
                },
              ],
            },
            {
              title: "Package 3: Party mood",
              cost: "Total Cost: € 390/person",
              items: [
                {
                  title: "Accelerated Spanish Course",
                  description:
                    "5 days - 4 hours/day (9:00 - 13:00 or 14:00 - 18:00)<br>Registration, materials and books are included",
                },
                {
                  title: "Activities (5 Days)*",
                  description: `<ul>  
                    <li>Touch music karaoke bar</li>
                    <li>Night Club</li>
                    <li>Pub crawl</li>
                  </ul>
                  <ul>  
                    <li>Local tapas evening</li>
                    <li>Moco Museum</li>
                  </ul>`,
                },
                {
                  title: "Airport Transfer",
                  description: "Included, by public bus",
                },
                {
                  title: "Public travel card",
                  description: "With 10 journeys by metro/bus",
                },
                {
                  description:
                    "Duration: 5 days from Monday to Friday during July, August or September. Starting Day: you can join every Monday<br>* Depending on availability, activities may change",
                },
              ],
            },
          ],
        },
        {
          id: "winter_packages",
          title: "WINTER PACKAGES",
          tables: [
            {
              title: "Package 1: Adventure Mood",
              cost: "Total Cost: € 370/person",
              items: [
                {
                  title: "Accelerated Spanish Course",
                  description:
                    "5 days - 4 hours/day (9:00 - 13:00 or 14:00 - 18:00)<br>Registration, materials and books are included",
                },
                {
                  title: "Activities (5 Days)*",
                  description: `<ul>  
                    <li>Park Güell</li>
                    <li>Paddle Surf</li>
                    <li>Costa Brava hiking</li>
                  </ul>
                  <ul>  
                    <li>Walking tours</li>
                    <li>Montjuïc mountain</li>
                  </ul>`,
                },
                {
                  title: "Airport Transfer",
                  description: "Included, by public bus",
                },
                {
                  title: "Public travel card",
                  description: "With 10 journeys by metro/bus",
                },
                {
                  description:
                    "Duration: 5 days from Monday to Friday during December, January or February Starting Day: you can join every Monday<br>* Depending on availability, activities may change",
                },
              ],
            },
            {
              title: "Package 2: Chilling Mood",
              cost: "Total Cost: € 390/person",
              items: [
                {
                  title: "Accelerated Spanish Course",
                  description:
                    "5 days - 4 hours/day (9:00 - 13:00 or 14:00 - 18:00)<br>Registration, materials and books are included",
                },
                {
                  title: "Activities (5 Days)*",
                  description: `<ul>  
                    <li>Jardí Botànic de Barcelona</li>
                    <li>Visit to Museo Picasso</li>
                    <li>Visit to la Pedrera</li>
                  </ul>
                  <ul>  
                    <li>Visit to Casa Vicens</li>
                    <li>Yoga class</li>
                  </ul>`,
                },
                {
                  title: "Airport Transfer",
                  description: "Included, by public bus",
                },
                {
                  title: "Public travel card",
                  description: "With 10 journeys by metro/bus",
                },
                {
                  description:
                    "Duration: 5 days from Monday to Friday during December, January or February Starting Day: you can join every Monday<br>* Depending on availability, activities may change",
                },
              ],
            },
            {
              title: "Package 3: Party mood",
              cost: "Total Cost: € 390/person",
              items: [
                {
                  title: "Accelerated Spanish Course",
                  description:
                    "5 days - 4 hours/day (9:00 - 13:00 or 14:00 - 18:00)<br>Registration, materials and books are included",
                },
                {
                  title: "Activities (5 Days)*",
                  description: `<ul>  
                    <li>Touch music karaoke bar</li>
                    <li>Night Club</li>
                    <li>Pub crawl</li>
                  </ul>
                  <ul>  
                    <li>Local tapas evening</li>
                    <li>Moco Museum</li>
                  </ul>`,
                },
                {
                  title: "Airport Transfer",
                  description: "Included, by public bus",
                },
                {
                  title: "Public travel card",
                  description: "With 10 journeys by metro/bus",
                },
                {
                  description:
                    "Duration: 5 days from Monday to Friday during December, January or February. Starting Day: you can join every Monday<br>* Depending on availability, activities may change",
                },
              ],
            },
          ],
        },
      ],
    },

    footer: {
      title: "We just started!",
      card1: {
        title: "Meet your teachers",
        body: "Know a little bit more about our team",
      },
      card2: {
        title: "How many levels are there?",
        body: "Whether you're a beginner or an advanced learner, there's a course for you",
      },
      card3: {
        title: "Who said learning couldn't be fun?",
        body: "We have different extra cultural activities every month. Check them out!",
      },
    },

    contact_form: {
      location: "Aerial view from La Sagrada Família, Barcelona",
    },
  },
  pathway: {
    title: "Spanish courses & Pathway <br> Program Package",
    section1: {
      header: "Discover",
      subheader:
        "Your Pathway to Success with our preparatory course to nail your admissions exams for Public Universities in Spain",
      title:
        "If you're planning on entering the Spanish educational system and pursuing your higher studies in Spain, you're in the right place! ",
      paragraph1:
        "We offer the most comprehensive Spanish course + university entrance exam (Selectividad) preparation course for international students or those with an International Baccalaureate. Our program ensures thorough preparation for all subjects required to enter your chosen degree program in Spain. With a guaranteed highest admission score and a 98% Exam Success Rate, you'll have the opportunity to apply to the country's top universities.",
      paragraph2:
        "This annual Selectividad course is specifically tailored for students who have completed an International Baccalaureate or studied high school outside of Spain or the Spanish educational system. Whether you're an international student or have a non-Spanish educational background, our course is designed to meet your needs and prepare you for success in the Spanish higher education system.",
    },
    why: {
      header: "Why choose <br> <span class='text-tertiary'>our package</span>",
      reasons: [
        {
          title: "98% Exam Success Rate",
          desc: "Ace your exams with our proven track record.",
        },
        {
          title: "Flexible Part-Time Work",
          desc: "Balancing 30 hours/week alongside studies, and full-time during vacations.",
        },
        {
          title: "High-Approval Visa",
          desc: "Enjoy a 1-year Category D visa, permanent residence and work rights, ensuring a seamless entry into your academic journey in Spain.",
        },
        {
          title: "53 Public Universities",
          desc: "Choose from a diverse range of institutions across Spain at the same prices as Spanish students (fees between € 800-2.500/academic year).",
        },
        {
          title: "Selectivity Exams",
          desc: "Only 6 subjects in order to take the exams",
        },
        {
          title: "Nationality of the students",
          desc: "All students must take the SELECTIVITY exam regardless of nationality.",
        },
      ],
    },
    explorer: {
      pretitle: "Invest in your <br> educational future in Spain",
      title: "In-Depth Explorer package",
      subtitle: "Total Investment: € 5.900",
      table: {
        rows: [
          {
            cols: [
              {
                title: "Accelerated Spanish Course",
                items: [
                  "<span class='text-underlined text-tertiary'>6 months intensive course</span> from A1 to B2 level starting in July.",
                  "Includes books and registration",
                ],
              },
              {
                title: "Pathway Program",
                items: [
                  "5 months duration, starting in January.",
                  "Coursework, materials, and homologation process",
                  "Pre-registration for exams and university (excludes exam and university fees)",
                ],
              },
            ],
          },
          {
            cols: [
              {
                class: "text-center",
                title: "Study Journey Timeline",
                desc: "July: Commence your Spanish odyssey <br>January: Enter the Pathway program",
              },
            ],
          },
        ],
      },
      cta: "I WANT TO KNOW MORE",
    },
    preparation: {
      header: "Preparation for <span class='text-tertiary'>Selectividad</span>",
      row1: {
        title: "Selectividad <br> <span class='text-tertiary'>Exam</span>",
        subtitle:
          "What are the advantages of the annual selectividad course for international students? ",
        desc: "The university entrance exam in Spain is structured around the content of the Spanish high school curriculum, making it highly beneficial for students from different educational systems to seek guidance from our experienced teachers. Year after year, our teachers assist hundreds of students in successfully gaining admission to Spanish universities. Moreover, our Selectividad preparation courses offer comprehensive support to ensure that students can pursue their preferred university options aligned with their interests.",
      },
      row2: {
        title:
          "How do we prepare you for the annual Selectividad course for international students",
        subtitle:
          "Our annual Selectividad course at UNED provides students with the best tools to pass university entrance exams.",
        desc: "You will have access to the updated curriculum right from the start of the course and ample time to prepare for all subjects. Our team of professionals, including coordinators and teachers, will assist you in establishing a study routine and achieving the consistency necessary to reach your goals. Our method emphasizes regular practice through exercises and exam simulations, ensuring comprehensive coverage of all materials. With guided support from day one, you can progress confidently in your preparation. If you aim to secure admission to your desired degree program after completing high school in another educational system, our annual Selectividad preparation course at UNED is undoubtedly the best choice for you.",
      },
    },
    journey: {
      cta: "I WANT TO KNOW MORE",
      header:
        "Your <br> academic <br> <span class='text-tertiary'>journey</span>",
      sections: [
        {
          title: "Navigating Spanish language and university enrollment",
          items: [
            {
              body: "<b>Step 1:</b> The student will begin the Spanish course in July, which will continue for six months until December. This course is mandatory because proficiency in Spanish is necessary to commence the pathway course, which is conducted in Spanish.",
            },
            {
              body: "<b>Step 2:</b> In January, following the completion of the Spanish course, the student will commence the pathway course.",
            },
            {
              body: "<b>Step 3:</b> The pathway course is expected to conclude in May",
            },
            {
              body: "<b>Step 4:</b> Following the completion of the pathway course in May, the student will be required to take the Selectividad test, scheduled to take place from May 20th until the end of the month. The exam consists of six parts: four in the mandatory general phase covering Spanish language and literature, a foreign language (English), the history of Spain, and a choice between mathematics or foundations of the arts. Additionally, there are two exams in the optional specific phase, which include health sciences, technology or economics, and humanities and social studies.",
            },
            {
              body: "<b>Step 5:</b> After the Selectividad test, there will be a waiting period of approximately 10 days for the exam results. During this time, we inform the students of their results and offer priority in university enrollment based on their performance.",
            },
            {
              body: "<b>Step 6:</b> With the Selectividad grades in hand, we then meet with our students to assist them in completing registrations at all the universities they are interested in attending.",
            },
            {
              body: "<b>Step 7:</b> Around July, students receive the admission letter from the university, which they use to initiate the renewal process for their stay in Spain.",
            },
            {
              body: "<b>Step 8:</b> In the first week of September, students commence their first year of the chosen degree program at the university.",
            },
          ],
        },
        {
          title: "Documents Needed",
          items: [
            {
              body: "<b>Only valid passport:</b> For the school to process the Student Visa Enrollment Letter, the only document needed is a valid passport of the student.",
            },
          ],
        },
        {
          title: "Payment and Enrollment Process",
          items: [
            {
              body: `<p>Once the school receives the student's passport, we will promptly mail the student a conditional offer letter containing course details (such as start date and duration) along with bank details for payment. </p>

              <p>
                <b>Students can choose to pay via:</b>
              </p>

              <ul>
                <li>Bank transfer</li>
                <li>Cash</li>
                <li>Credit card</li>
              </ul>`,
            },
          ],
        },
        {
          title: "Visa Processing",
          items: [
            {
              body: "We highly recommend that students complete their enrollment process by the month of <b>March</b> to allow ample time for visa processing and to avoid any potential delays.",
            },
          ],
        },
      ],
    },
    university: {
      header:
        "University <br> <span class='text-tertiary'>Preparation Course</span>",
      title:
        "From A1 to B2 <br> <span class='text-tertiary'>in 6 months!</span>",
      desc1:
        "Specially designed for non-EU students, this University Preparation Course (Pathway) will assist you in your learning goals to access the Spanish university of your choice and enable you to continue studying in Spain and obtain a higher diploma.",
      subtitle: "What you will learn",
      desc2: `<p>
        Upon completion of the B2 level of Spanish, you will acquire a comprehensive
        set of skills and knowledge that will allow you to interact and participate in
        conversations and linguistic situations of a higher level.
      </p>

      <p class="text-tertiary">Express yourself freely!</p>

      <p>
        You will be proficient in providing detailed information on various topics,
        expressing yourself fluently both orally and in writing. You will also have
        the ability to use communicative strategies to engage in conversations across
        different contexts and situations.
      </p>

      <p class="text-tertiary">Strong grasp of verb tenses</p>

      <p>
        Grammatically, you will have a strong grasp of verb tenses, including past
        tenses, future expressions, and imperatives, as well as the usage of ser and
        estar, temporal markers, discourse connectors, various sentence constructions
        including conditional and relative clauses, indirect style, and impersonal
        constructions. You will also be proficient in expressing personal likes and
        dislikes.
      </p>

      <p>
        Overall, completing the B2 level in Spanish will equip you with a diverse
        range of linguistic abilities essential for effective communication in
        Spanish-speaking universities and environments.
      </p>`,
      subtitle2: "Schedule",
      desc3: `<ul class="disc-style disc-start disc-tertiary">
        <li class='mb-4'>
          <p class="text-tertiary mb-2">Barcelona Campus</p>
          <p>
            Morning: 9:30 am - 1:30 pm <br />
            Afternoon: 2:00 pm - 6:00 pm
          </p>
        </li>
        <li>
          <p class="text-tertiary mb-2">Madrid Campus</p>
          <p>
            Morning: 9:00 am - 1:00 pm <br />
            Afternoon: 13:30 pm - 5:30 pm
          </p>
        </li>
      </ul>`,
    },

    footer: {
      title: "We just started!",
      card1: {
        title: "Meet your teachers",
        body: "Know a little bit more about our team",
      },
      card2: {
        title: "How many levels are there?",
        body: "Whether you're a beginner or an advanced learner, there's a course for you",
      },
      card3: {
        title: "Who said learning couldn't be fun?",
        body: "We have different extra cultural activities every month. Check them out!",
      },
    },

    contact_form: {
      location: "Aerial view from El arco del triunfo, Barcelona",
    },
  },
  placement_test: {
    title: "Placement Test",
    header: {
      title: 'How much do you <span class="text-tertiary">know?</span>',
      paragraph1:
        "Take our free Spanish or English proficiency test to assess your language level! This test is a part of our initial assessment for new students, ensuring they start at an appropriate level and can progress with ease.",
      paragraph2:
        "Don't miss the chance to test your knowledge today, completely free of charge!",
    },
    spanish_test: {
      title: "Spanish Level Test",
      exam_cta: "Take your Spanish test now!",
      description:
        "Welcome to our language proficiency test! This assessment is designed to evaluate your Spanish language skills. Are you ready to get started? Please follow the instructions below",
      instruction1: "The questions are multiple choice",
      instruction2:
        "Read the questions and mark one answer option. There is only one correct answer to each question",
      instruction3:
        "The questions get harder as the test progresses (from A1 to C2)",
      instruction4:
        "If the questions are too difficult, skip to the next one and when you are done click on submit to finish your test",
      instruction5:
        "Thank you very much for your time and good luck in your test!",
    },
    english_test: {
      description:
        "Welcome to our language proficiency test! This assessment is designed to evaluate your English language skills. Are you ready to get started? Please follow the instructions below:",
      instruction1: "The questions are multiple choice",
      instruction2:
        "Read the questions and mark one answer option. There is only one correct answer to each question",
      instruction3:
        "The questions get harder as the test progresses (from A1 to C2)",
      instruction4:
        "If the questions are too difficult, skip to the next one and when you are done click on submit to finish your test",
      instruction5:
        "Thank you very much for your time and good luck in your test!",
      title: "English Level Test",
      exam_cta: "Take your English test now!",
    },
    footer: {
      title: "Let us help you",
      card1: {
        title: "How many levels are there?",
        body: "Whether you're a beginner or an advanced learner, there's a course for you",
      },
      card2: {
        title: "Which course will best fit your needs?",
        body: "Are you in a rush or do you plan a long stay? We have options for everyone",
      },
      card3: {
        title: "Do you have any doubts about the Visa?",
        body: "Here's some information to help you start the process",
      },
    },
    contact_form: {
      location: "Port Vell, Barcelona",
    },
  },
  exams_and_certifications: {
    title: "Exams and Certificates",
    header: {
      title: 'Finish <br> <span class="text-tertiary">line!</span>',
      paragraph1: "What happens when you finish?",
      paragraph2:
        "What are the certifications you're going to get when you finish your course? Will you have to pass any exams? Here's all the information you need to know regarding this important topics.",
    },
    exams: {
      title: "Exams",
      types: ["Official ELE DELE / SIELE", "CEFR self assessment table"],
      paragraph1:
        "At NL College, we highly recommend official ELE exams such as DELE or SIELE for obtaining a certification of linguistic proficiency. However, we also encourage students to reflect on their own progress and abilities using the CEFR self-assessment table. Together with their teacher, students determine whether they are ready to move to the next level or need additional support. To assess progress and achievement of course objectives,",
      paragraph2:
        "NL College utilizes two evaluation instruments: a self-evaluation activity at the end of each course and an exam based on the DELE model from Instituto Cervantes, which consists of four tests to certify student assimilation of the level and readiness for advancement.",
    },
    certificates: {
      title: "Certificates",
      types: ["Official ELE DELE / SIELE", "CEFR self assessment table"],
      information: {
        card1: {
          title: "Certification of attendance",
          detail1: "Duration of your classes",
          detail2: "Total hours",
          detail3: "Level achieved",
        },
      },
      paragraph1:
        "Upon completion of your studies at NL College, you will receive a certificate of attendance that includes the duration of your classes, total hours and level achieved. However, the only official certification of linguistic proficiency is the DELE (Diploma of Spanish as a Foreign Language) from the Instituto Cervantes or the SIELE (International Service for the Evaluation of the Spanish Language",
      paragraph2:
        "As a DELE examination center, NL College Madrid provides information about the exam dates and offers preparation courses for the same. Please check here for more details.",
    },
    footer: {
      title: "You may find this helpful",
      card1: {
        title: "How many levels are there?",
        body: "Whether you're a beginner or an advanced learner, there's a course for you",
      },
      card2: {
        title: "Which course will best fit your needs?",
        body: "Are you in a rush or do you plan a long stay? We have options for everyone",
      },
      card3: {
        title: "Do you have any doubts about the Visa?",
        body: "Here's some information to help you start the process",
      },
    },
    contact_form: {
      location: "Streets from Madrid",
    },
  },
  prices: {
    title: "Prices List",
    talk_to_agent: "I WANT TO TALK TO AN AGENT",
    header: {
      title: 'All the <span class="text-primary">options</span>',
      paragraph1:
        "NL College offers highly competitive prices, striking a perfect balance between quality, cultural immersion, exceptional facilities and excellent cost-benefit.",
      paragraph2:
        "With several course options available, you can choose the one that best fits your needs, whether you wish to study with us for a short or extended period of time.",
    },
    course_prices: {
      header: {
        title: "Course prices",
        paragraph1:
          "Our school is ready to welcome you! We are delighted to offer the most competitive prices with an exceptional balance between quality, unique facilities and Spanish immersion experience.",
        paragraph2:
          "With options between Accelerated (20 hours per week), Semi-Intensive (10 hours per week) our Extensive (4 hours per week).",
        paragraph3:
          "Choose the course that best fits your needs, the number of weeks and get ready to jump to the next level in your life.",
      },
      details: {
        title: "Are you looking for one course specifically?",
        subtitle1: "Discover our full range of Spanish and English courses",
        subtitle2:
          "Do you want to study for more than 12 weeks? That's great! Introduce the number of weeks you'd like to stay with us and calculate your price.",
      },
      prices_details: {
        desc1: {
          title: "Registration fee",
          subtitle: "€ 40 - One time only",
        },
        desc2: {
          title: "Siele Exam + Preparation",
          subtitle: "€ 199",
        },
        desc3: {
          title: "Book",
          subtitle: "€ 35",
        },
      },
      private_classes: {
        title: "Private classes",
        paragraph1:
          "Flexible and according to your needs, the teacher will adapt to your learning style and specific Spanish knowledge goals. You will get the schedule that you want, it doesn't matter if you are available in the morning or in the evening, any time and any day, just let us know and we will do it for you",
        paragraph2:
          "It can be for a person, or small group of up to 3 people, where you can split the cost between your friends and save more money.",
        paragraph3:
          "The classes will be in person at the school or we also offer the option to go wherever you are, either at your home or workplace. Also you can choose virtual private classes, which have the same price as the in person private lessons, but using the video conferencing platform.",
      },
      dele_classes: {
        title: "DELE and CCSE Exam <br> preparation course",
        paragraph1:
          "DELE is organised by the Instituto Cervantes and it's an internationally recognized qualification in Spanish Language competency.",
        paragraph2:
          "This is the most important exam where you are going to show your Spanish level. It opens many doors in your professional life all over the world and also with the admission to many Spanish Universities. Get ready for the exam with our DELE Preparation Course.",
      },
      extra_services: {
        title: "Extra services",
      },
      cta: {
        title: "Do you want to know what promotions we currently have?",
        subtitle: "Talk to one of our agents!",
      },
    },
    payments: {
      title: 'Payment <span class="text-primary">options</span>',
      header:
        "After you've made the decision to invest in your knowledge, paying is a breeze with our straightforward options. Select from online payment, bank transfer, or paying directly at the school.",
      online_payments: {
        title: "Online payments",
        details:
          "Easy, fast and efficient. Through the Flywire platform you enter the necessary information and your payment is done.",
        flywire: {
          barcelona: "Flywire <br /> BARCELONA",
          madrid: "Flywire <br /> MADRID",
        },
      },
      bank_transfer: {
        title: "Bank Transfer",
        details:
          "You can also pay by bank transfer. <br /> Our account details are:",
      },
      at_school: {
        title: "At our school",
        details:
          "If you are already in Spain, we also offer the option to pay at school. You can pay in cash or by credit card until 3 pm on the Friday before the start of the course. If you choose this option, please let us know in advance.",
      },
    },
    footer: {
      title: "Let us help you",
      card1: {
        title: "Do you have any other questions regarding payments?",
        body: "Check our FAQs",
      },
      card2: {
        title: "How many levels are there?",
        body: "Whether you're a beginner or an advanced learner, there's a course for you",
      },
      card3: {
        title: "Do you have any doubts about the Visa?",
        body: "Here's some information to help you start the process",
      },
    },
    contact_form: {
      location:
        "View from the inside of Palacio de Cristal in Parque del retiro, Madrid",
    },
  },
  student_life: {
    title: "Student Life",
    header: {
      title: 'Welcome to your new <span class="text-secondary">life!</span>',
      paragraph1:
        "Moving to a new country can be an overwhelming experience, but we want to ensure that your transition to Barcelona or Madrid is as enjoyable and stress-free as possible.",
      paragraph2:
        "As a school, we're here to support you every step of the way. From the moment you decide to join us, we'll provide you with all the information you need to make the move, including useful tips and advice on housing, transportation, and cultural differences.",
      paragraph3:
        "If you have any questions or concerns, our FAQ page is a great resource, or you can always reach out to us directly. We're committed to providing you with the support you need to make the most of your experience in Spain.",
    },

    tabs: [
      {
        col_class: "col-12",
        image: "student-visa",
        title: "Student Visa",
        links: [
          {
            title: "All you need to know",
            to: { name: "student_visa" },
          },
        ],
      },
      {
        col_class: "col-12",
        image: "extra-activities",
        title: "Extra activities",
        links: [
          {
            title: "Who said  learning couldn’t be fun?",
            to: { name: "extra_activities" },
          },
        ],
      },
      {
        col_class: "col-lg-6",
        image: "accommodation",
        title: "Accommodation",
        links: [
          {
            title: "Discover all our options",
            to: { name: "accommodation" },
          },
        ],
      },
      {
        col_class: "col-lg-6",
        image: "insurance",
        title: "Health insurance",
        links: [
          {
            title: "Discover all our options",
            to: { name: "medical_insurance" },
          },
        ],
      },
    ],

    footer: {
      title: "But wait, there's more!",
      card1: {
        title: "Meet your teachers",
        body: "Know a little bit more about our team",
      },
      card2: {
        title: "Which course will best fit your needs?",
        body: "We have options for everyone",
      },
      card3: {
        title: "Do you have any questions?",
        body: "Check our FAQs to find the anwer to any doubts you may have",
      },
    },
    contact_form: {
      location: "Aerial view from the Barcelona bay",
    },
  },
  student_visa: {
    title: "Student VISA",
    header: {
      title: "Let's make it <span class='text-secondary'>easy</span>",
      paragraph1:
        "We know that applying for a long-term student visa can be a daunting task, especially if you're not familiar with the process. That's why we're here to help. With our extensive experience in advising students during the application process, and our partnership with specialised visa lawyers, you can trust that you're in good hands.",
    },
    details: {
      reminder:
        "We remind you that the laws are constantly changing and the requirements differ for each country, so be sure to consult an expert when starting your process.",
    },
    footer: {
      title: "You may find this helpful",
      card1: {
        title: "How many levels are there?",
        body: "Whether you're a beginner or an advanced learner, there's a course for you",
      },
      card2: {
        title: "Which course will best fit your needs?",
        body: "Are you in a rush or do you plan a long stay? We have options for everyone",
      },
      card3: {
        title: "Do you have any doubts about the Visa?",
        body: "Here's some information to help you start the process",
      },
    },
    contact_form: {
      location: "Gran Vía, Madrid",
    },
    sections: [
      {
        title: "First things first",
        untilEnd: true,
        items: [
          {
            subtitle:
              "To get started, all you need to do is enroll in our Accelerated Spanish course.",
            body: `<p>Once you've fully paid for the course, we'll guide you through
              every step of the visa application process. Our goal is to make the process
              as smooth and hassle-free as possible, so you can focus on what really
              matters - learning and growing with us.</p>`,
          },
        ],
      },
      {
        title: "Do have this in consideration",
        untilEnd: true,
        items: [
          {
            title: null,
            body: `
              <p>
                You can apply for a visa at the Consulate General of Spain in your home
                country, or you can apply after you enter Spain as a tourist. Please note
                that there is a time limit for applying for a visa from within Spain, so if
                this is your situation, please consult with us
              </p>
            `,
          },
          {
            title: null,
            body: `
              <p>
                The documents must be translated and apostatized
              </p>
            `,
          },
          {
            title: null,
            body: `
              <p>
                By choosing courses of 27 weeks or more, you will have the right to renew your
                student visa, if you wish to do so in the future
              </p>
            `,
          },
          {
            title: null,
            body: `
              <p>
                We have partnerships with companies that can assist you with visa applications in
                Spain and other countries for an additional cost. Feel free to contact us to learn more.
              </p>
            `,
          },
        ],
      },
      {
        title: "Documents required",
        untilEnd: false,
        items: [
          {
            body: `<p> National visa application form </p>`,
          },
          {
            body: `<p> Valid and current photograph and passport </p>`,
          },
          {
            body: `<p> Enrolment letters sent by NL College </p>`,
          },
          {
            body: `<p> Proof of economic means (currently € 600 per month of visa) </p>`,
          },
          {
            body: `<p> Certificate of criminal record </p>`,
          },
          {
            body: `<p> Medical certificate </p>`,
          },
          {
            body: `<p> Residence permit </p>`,
          },
          {
            body: `<p> Payment of visa fee (currently € 80) </p>`,
          },
        ],
      },
    ],
  },
  accommodation: {
    title: "Accommodation",
    header: {
      title: 'Make yourself <span class="text-secondary">at home!</span>',
      paragraph1:
        "We know how important it is to feel that someone is supporting you - especially when it comes to a move to a foreign country",
      paragraph2:
        "This is why we have great options of accommodation companies that will make this process easier for an additional cost.",
      paragraph3:
        "You will be housed in a safe location and as close to the school as possible to maximise your comfort thanks to our partner network.",
    },
    gallery: {
      photo1: "Shared apartment",
      photo2: "Student Residence",
      photo3: "Hostel",
    },
    footer: {
      title: "You may find this helpful",
      card1: {
        title: "How many levels are there?",
        body: "Whether you're a beginner or an advanced learner, there's a course for you",
      },
      card2: {
        title: "Which course will best fit your needs?",
        body: "Are you in a rush or do you plan a long stay? We have options for everyone",
      },
      card3: {
        title: "Do you have any doubts about the Visa?",
        body: "Here's some information to help you start the process",
      },
    },
    contact_form: {
      location: "Aerial view of Barcelona",
    },
    sections: [
      {
        title: "Shared apartment",
        untilEnd: true,
        items: [
          {
            title: null,
            body: `<p>
                Sharing a flat is one of the best options for students in Barcelona.
                Students will have a private room with their own bed (single or double).
                They will share a flat with other people (from 2 to 5) and will have
                access to the kitchen, bathroom and common areas such as the living room
                and dining room.
              </p>
              <p>
                Food is not included and bills are usually added on top of the monthly
                rent (electricity, gas, water and internet).
              </p>
              <p>
                NL College has a partnership with <a href="https://www.spotahome.com/" class="text-secondary">
                Spotahome</a>, which offers a 25% of discount in the registration fees, and 
                <a href="https://www.resa.es/" class="text-secondary"> Resa</a>, offering a 10% discount for
                each month that a student stays with them.
              </p>`,
          },
        ],
      },
      {
        title: "Student Residence",
        untilEnd: true,
        items: [
          {
            title: null,
            body: `<p>
                The student houses are perfect for students who want to live surrounded by other
                local and international students, in great facilities with all bills included
                (electricity, gas, water and internet). Students have access to their own room
                and bathroom (single or shared). The student houses have wi-fi, common areas
                such as study rooms, gyms, 24h surveillance and cleaning services.
              </p>`,
          },
        ],
      },
      {
        title: "Hostel",
        untilEnd: true,
        items: [
          {
            title: null,
            body: `<p>
                A hostel is the perfect option for short stays. Students can book from one week
                upwards. Students can have a private room, which is more expensive, or they can
                share with 2, 3, 4, or even 20 people. The hostel offers open kitchen and bar
                service, as well as sheets and wi-fi. When booking the hostel, there is no need
                to pay a deposit or registration fees.
              </p>`,
          },
        ],
      },
    ],
  },
  medical_insurance: {
    title: "Medical Insurance",
    header: {
      title: 'We have you <span class="text-secondary">covered</span>',
      paragraph1:
        "According to Spanish government rules, it is mandatory for students to have adequate medical insurance during their stay in Spain, and NL College offers some insurance options to be purchased at an additional cost.",
      paragraph2:
        "If your student visa is long term, it must have full medical coverage, be without a waiting period and without a copay. This also applies to students who plan to stay longer than 90 days. We collaborate with some of the largest insurance companies with the sole purpose of providing the student with the greatest guarantees.",
      paragraph3: "Please consult us for more specific information.",
    },
    footer: {
      title: "You may find this helpful",
      card1: {
        title: "How many levels are there?",
        body: "Whether you're a beginner or an advanced learner, there's a course for you",
      },
      card2: {
        title: "Which course will best fit your needs?",
        body: "Are you in a rush or do you plan a long stay? We have options for everyone",
      },
      card3: {
        title: "Who said learning couldn't be fun?",
        body: "We have different extra cultural activities every month. Check them out!",
      },
    },
    contact_form: {
      location: "Gran Vía, Madrid",
    },
  },
  extra_activities: {
    title: "Extra Activities",
    header: {
      title: "Let's make it <span class='text-secondary'>different!</span>",
      paragraph1:
        "At NL College, we believe that learning a language is more than just studying in a classroom. That's why we offer a wide range of free activities and additional classes to our students, providing opportunities to experience the Spanish culture firsthand and make lifelong international friendships.",
      paragraph2:
        "Our program also includes social activities and excursions, allowing students to fully immerse themselves in the local culture and explore the surrounding area. With our schools located in renowned cities, we offer unique activities tailored to each location, so you can make the most of your language learning journey.",
    },
    section1: {
      title:
        'We had the time <span class="text-secondary">of our lives!</span>',
      subtitle:
        "Our students had an amazing adventure with us, going from the snowy mountains of the Pyrenees to the sunny beaches of Tossa de Mar and Valencia! They made tons of new friends from all over the world while singing their hearts out at karaoke, dancing at traditional parties, and exploring everything Spain has to offer. It was an unforgettable journey filled with laughter, learning, and incredible memories",
    },
    section2: {
      title: "What our students said after joining us in our extra activities",
      reviews: [
        {
          comment:
            "It was interesting to spend time with our classmates and Erik. I have a very nice memory. Thank you very much!",
          person: "Lola - Chinese student",
        },
        {
          comment:
            "Thank you for the activities. You're always very kind to us.",
          person: "Pedro - Brazilian student",
        },
        {
          comment:
            "Together, we managed to turn every moment into an unforgettable adventure. Thank you very much for making our participation in the activity so special!",
          person: "Asal - Iranian student",
        },
        {
          comment:
            "I want to thank everyone for everything, and a special thank you to the coordinator who is always so kind! We learn, we talk, we have fun - everything is so enjoyable, and I feel happy knowing there are activities to come.",
          person: "Akak -Algerian student",
        },
        {
          comment:
            "I have participated in the cultural activities of the school and I really enjoy them. The organization seems very good to me, and the places we visit are also very interesting. It's always an opportunity to meet other students from different places. The coordinators’ company is also very pleasant, and they’re very attentive.",
          person: "Márcia - Brazilian student",
        },
        {
          comment:
            "As employees, we can also participate in the activities. We recently went to La Molina, and it was an absolute blast! It was so nice to be able to meet the students in our school more personally. The place itself was insanely beautiful. It was such a fun day, honestly.",
          person: "Alba - staff",
        },
      ],
    },
    footer: {
      title: "Let us help you",
      card1: {
        title: "How many levels are there?",
        body: "Whether you're a beginner or an advanced learner, there's a course for you",
      },
      card2: {
        title: "Which course will best fit your needs?",
        body: "Are you in a rush or do you plan a long stay? We have options for everyone",
      },
      card3: {
        title: "Do you have any doubts about the Visa?",
        body: "Here's some information to help you start the process",
      },
    },
    contact_form: {
      location: "Visiting Segovia",
    },
  },
  contact_us: {
    title: "Contact Us",
    header: {
      title: 'We want to hear from <span class="text-secondary">you!</span>',
      paragraph1:
        "We understand that sometimes you may need some additional help or support, and we're here to make that process as easy and seamless as possible.",
      paragraph2:
        "Our team of support staff is always ready and available to answer any questions or concerns you may have. We believe that providing exceptional support is just as important as providing exceptional education, and we strive to make your experience with NL College as positive and rewarding as possible.",
      paragraph3:
        "So, if you need any help or support, please don't hesitate to contact us. We're here to assist you every step of the way and look forward to hearing from you soon!",
    },
    subtitle: "Contact us and start planning your education with us!",
    accreditation: "Center accredited by the Cervantes Institute",
  },
  pathway_landing: {
    title: "Work and study in Barcelona",
    subtitle:
      "Discover Your Pathway to Success with our preparatory course. Get to work in Barcelona while",
    form: {
      title: "Start now!",
      subtitle: "Don't miss on this great opportunity",
      name: "Full name",
      email: "E-mail address *",
      phone: "Whatsapp number *",
      note: "* Mandatory fields",
      button: "GO!",
    },
    advantages: {
      title: "Advantages of investing in our package",
      items: [
        {
          title: "98% Exam Success Rate",
          subtitle: "Ace your exams with our proven track record.",
        },
        {
          title: "Flexible Part-Time Work",
          subtitle:
            "Balancing 30 hours/week alongside studies, and full-time during vacations.",
        },
        {
          title: "High-Approval Visa",
          subtitle:
            "Enjoy a 1-year Category D visa, permanent residence and work rights, ensuring a seamless entry into your academic journey in Spain.",
        },
        {
          title: "53 Public Universities",
          subtitle:
            "Choose from a diverse range of institutions across Spain at the same prices as Spanish students (fees between € 800- € 2.500 /academic year).",
        },
        {
          title: "Selectivity Exams",
          subtitle: "Only 6 subjects in order to take the exams",
        },
        {
          title: "Nationality of the students",
          subtitle:
            "All students must take the SELECTIVITY exam regardless of nationality.",
        },
      ],
    },
    invest: {
      title: "Invest in your educational future in Spain",
      subtitle:
        "Learn Spanish with NL College and get access to your dreamed university in Spain.",
      button: "LEARN MORE",
    },
  },
  summer_packages: {
    title: "Prepare to have the best summer in Spain  with NL College",
    from: "from",
    price: "330",
    currency: "€",
    subtitle:
      "Choose one of our Summer Language Experience Packages during June, July and August ",
    form: {
      title: "Start now!",
      subtitle: "Don't miss on this great opportunity",
      name: "Full name",
      email: "E-mail address *",
      phone: "Whatsapp number *",
      note: "* Mandatory fields",
      button: "GO!",
    },
    packages: {
      title:
        "With our Summer Packages, you'll be able to combine the best of both worlds",
      subtitle:
        "Whether you're seeking a blend of learning and holiday experiences, or someone eager to explore the wonders of Spain in summer, we have a package for you. ",
      foot: "Combine learning Spanish with the best of the European summer",
    },
    get_ready: {
      title: "Get ready for the best summer of your life!",
      subtitle:
        "Learn Spanish with NL College while experiencing the beauties of Barcelona and Madrid",
      button: "LEARN MORE",
    },
  },
  thanks: {
    title: "THANK YOU!",
    subtitle: "You're one step closer to start learning Spanish for good!",
    text: "One of our agents will contact you soon.",
  },
  thanksV2: {
    title: "Thank You for connecting with us!",
    subtitle: "Expect to hear from us shortly!",
    text: "While you wait to hear from us,why not have a look on our social channels? There's plenty to discover and we'd love to connect with you there!",
  },
  blog: {
    title: "Blog",
    header: {
      title: "Welcome to our <span class='text-primary'>blog</span>",
      subtitle:
        "This space will be your guide to everything you need to know about studying in Spain. From cultural adaptation tips to recommendations on the best places to explore, we are here to make your study journey an unforgettable experience.",
      desc: "Explore Spain and the world of language learning through our eyes! In this blog, you'll delve into a wide range of topics, including living in Madrid and Barcelona, tips for learning Spanish and English, guidance on starting your journey to move to Spain, and, not to be overlooked, updates and essential information about our school and campuses.",
      topics_title:
        "Are you looking for one specific topic? Check our blog categories:",
    },
    contact_form: {
      location: "Our students on their trip to Valencia",
    },
  },
  price_modal: {
    title: "Download",
    subtitle: "our price list and catalogue",
    pricelist: "Price List",
    brochure: "Brochure",
    errors: {
      terms: "Please accept terms and conditions",
      full_name: "Your name is required",
      email: "Your email is required",
      country: "Your coutnry is required",
      nationality: "Your nationality is required",
    },
  },
};
